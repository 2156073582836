
#overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(27, 28, 37, 0.9);
    z-index: 999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #a6282c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    display: none;

    .row{
        width: 100%;
    .overlay-search-form {
        display: flex;
        align-items: center;
        width: 50vw;
        margin: auto;
        input {
            width:100%;
            margin-bottom: 0;
            //padding-right: 40px;
            opacity: 1;
            background: transparent;
            font-size: 30px;
            height: auto;
            border: none;
            border-bottom: 2px solid rgb(215, 91, 95);
            color: #a6282c !important;
            padding-left: 0;
            font-weight: 700;
            opacity: 1;
            outline: none;

            &::placeholder {
                color: rgba(215, 91, 95);
            }

            @media (max-width:992px) {
                font-size: 20px;
            }
        }

        .overlay-search-submit {
            color: rgba(215, 91, 95);
            //position: absolute;
            //top: 12px;
            //right: -12px;
            font-size: 30px;
            &:hover {
                color: #a6282c;
            }
        }


        @media (max-width:700px) {
            width: 90vw;
        }
    }
}}



input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

#overlay2.loader{
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(27, 28, 37, 0.9);
    z-index: 999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #a6282c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
}
