@media screen and (max-width: 991px) {
    .gray-bg.cms-page {
        .container-fluid {
            .hall-content.container-fluid {
                top: -15px;

                .row {
                    .col-lg-4 {
                        .galleria {
                            .owl-carousel.single-hall-gallery-owl {
                                .owl-stage-outer {
                                    padding-left: 0;
                                }
                            }
                        }

                        .btn.btn-download {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0;

                            .fa.fa-download {
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .gray-bg.cms-page {
        .container-fluid {
            .hall-content.container-fluid {
                top: -40px !important;
            }
        }
    }
}
