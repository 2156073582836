@media screen and (max-width: 991px) {
    .events-hero-container {
        .container-fluid {
            & > .row {
                margin: 0;
            }
        }
    }

    .events-container {
        .row {
            &:nth-of-type(1) {
                margin: 0;
            }

            &:nth-of-type(3) {
                margin: 0;
            }

            .events-booking-info {
                padding: 0;
                margin: 0 20px;
            }

            .events-content {
                margin: 0 20px;
                padding: 0;

                .events-content-title {
                    margin-bottom: 10px;
                }

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        .grid-template-event-details {
            grid-template-areas:
                "main-text"
                "sidebar"
                "related" !important;

            .related {
                margin-top: 22px;
            }

            .sidebar {
                margin-top: 22px;
            }
        }

        .py-5 {
            & > .row {
                margin: 0 20px;

                .col-lg-7,
                .col-lg-5 {
                    padding: 0;
                }
            }

            .go-back {
                margin: 0 20px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .events-hero-container {
        .container-fluid {
            & > .row {
                margin: 0;
            }
        }
    }
}

.events-container {
    .desc {
        padding-right: 10px;
        padding-bottom: 16px;
    }

    .button__link {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 24px;
        text-align: center;
    }

    .events-owl {
        .banner-events {
            max-width: inherit;

            .banner-description {
                max-width: 80%;

                .title {
                    font-size: 20px;
                    font-weight: 600;
                }

                .event-details {
                    color: #333333;
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
    }

    .events-mobile {
        .title {
            display: inline;
        }

        .link {
            float: right;
            @media (max-width: 575px) {
                line-height: 29px;
            }
        }

        @media (max-width: 575px) {
            .title,
            .link {
                font-weight: 600;
            }
        }
    }

    p {
        @media (max-width: 567px) {
            margin-bottom: 0;
        }
    }

    .card {
        border-radius: 10px;

        .card-img-top {
            object-fit: cover;
            height: 100%;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        .card-text {
            padding-bottom: 28px;

            span {
                color: #333333;
                font-family: $open-sans;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 17px;
            }

            i {
                color: #333333;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 17px;
            }
        }

        .date {
            color: #cccccc;
            font-family: $montserrat;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 18px;
            max-width: 106px;
            display: inline-block;

            .first-letter {
                font-size: 48px;
                line-height: 44px;
                float: left;
                margin: -4px 4px 0 0;
            }
        }

        .button__link {
            color: #cf5b5e;
            font-size: 15px;
        }

        .button {
            &:hover {
                border: 2px solid black;

                a {
                    color: black;
                }
            }

            @media (max-width: 1082px) and (min-width: 768px) {
                float: unset !important;
            }
        }
    }

    .banner {
        //max-width: 560px;
        border-radius: 5px;
        position: relative;

        img {
            border-radius: 5px;
            height: 290px;
            @media (max-width: 575px) {
                height: 200px;
            }
        }

        .banner-description {
            max-width: 350px;
            padding: 20px 30px;
            position: relative;
            left: 50%;
            transform: translate(-50%, -55%);
            background-color: white;
            border-radius: 5px;
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

            .title {
                font-size: 20px;
                margin-bottom: 0;
            }

            .event-details {
                font-size: 12px !important;
                margin-bottom: 0;
            }

            @media (max-width: 570px) {
                transform: translate(-50%, -50%);
                padding: 10px;
                max-width: 400px;
                width: 80%;
                p {
                    font-size: 20px;
                    font-weight: 800;
                    @media (max-width: 575px) {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .breadcrumb {
        background-color: transparent;
        color: #999999;
        font-family: $montserrat;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;
        padding-bottom: 10px;
        padding-top: 35px;

        a {
            color: #999999;

            &:hover {
                color: unset;
            }
        }
    }

    .grid-title {
        font-family: $montserrat;
        height: 42px;
        //width: 286px;
        color: #000000;
        font-size: 34px;
        font-weight: bold;
        letter-spacing: 1px;
        // line-height: 42px;
        padding-top: 0px;
        padding-bottom: 60px;
    }
}

.card {
    &.flex__elements {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.event.card {
    border: 0;
    border-radius: 10px;
    margin-bottom: 22px;

    .card-body {
        padding: 20px;

        &.content__align {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            .title {
                display: block;
                height: auto;
                margin-bottom: 20px;
                height: 48px;
            }

            .card-text.location {
                padding-bottom: 0;
            }
        }

        .button__link {
            border: 2px solid #cf5b5e;
            border-radius: 5px;
            color: #cf5b5e;
            font-size: 15px;

            &:hover {
                background-color: #cf5b5e;
                transition: all 0.15s ease-in;
                color: white;
            }
        }
    }

    img {
        border-radius: 10px 10px 0 0;
        height: 160px;
        object-fit: cover;
    }

    .fallback-bg {
        height: 160px;
    }

    .suptitle,
    .time-stamp {
        i {
            font-size: 18px;
            letter-spacing: 0;
            color: #999999;
            padding-right: 10px;
        }

        span {
            color: #999999;
            font-family: $open-sans;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 17px;
            @media (max-width: 575px) {
                font-weight: 600;
            }
        }
    }

    .title {
        color: #000000;
        font-family: $montserrat;
        font-size: 18px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        height: 52px;
        @media (max-width: 575px) {
            font-weight: 600;
        }
    }
}

.event.card:hover {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.15s ease-in;
}

.gray-bg {
    .events-container.events-booking {
        .py-5 {
            .row {
                .col-lg-5 {
                    .events-booking-seats {
                        .events-booking-seats-items {
                            .cart-item {
                                .card-item-price {
                                    .dropdown {
                                        width: 100%;

                                        .btn.dropdown-toggle {
                                            display: flex !important;
                                            align-items: center !important;
                                            justify-content: space-between !important;
                                            width: 100%;
                                            position: relative;


                                            .price__dropdown {
                                                margin: 0;
                                                padding-left: 5px;
                                            }
                                        }

                                        .dropdown-menu.show {
                                            right: 0;

                                            .dropdown-item {
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: center;
                                                padding-bottom: 0;

                                                p {
                                                    margin: 0;
                                                    white-space: break-spaces;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.gray-bg {
    .events-booking {
        .py-5 {
            .row {
                .events-booking-map {
                    .map-navigation {
                        p {
                            font-size: 15px;
                            line-height: 24px;
                            letter-spacing: 1.07px;
                            color: #4c4c4c;
                            font-family: "Montserrat";
                            text-transform: uppercase;
                        }

                        .second-section {
                            p {
                                font-size: 10px;
                            }
                        }

                        .fas.fa-info {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            letter-spacing: 2.14px;
                            color: #4c4c4c;
                            border: 1px solid #000;
                            border-radius: 50%;

                            &::before {
                                width: 5px;
                            }
                        }
                    }

                    .disable-mask-active{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        background-color: #ddd;
                        opacity: 0.5;
                        z-index: 2;
                    }

                    #select_reservation, .select_reservation_subscription {
                        display: flex;
                        position: relative;
                        justify-content: space-between;
                        align-items: baseline;
                        flex-wrap: wrap;
                        width: 100%;
                        border: 1px solid #DDDDDD;
                        border-top: none;
                        padding-bottom: 32px;
                        margin-bottom: 32px;

                        .select_reservation__error {
                            width: 100%;
                            justify-content: flex-end;
                            display: flex;
                            padding: 0 15px;

                            span.error {
                                text-align: right;
                            }
                        }

                        .col-lg-4 {
                            .font-weight-bold {
                                margin: 0;
                                font-size: 15px;
                                line-height: 19px;
                                font-family: "Montserrat";
                                font-weight: bold;
                                letter-spacing: normal;
                                color: #000000;
                                text-transform: uppercase;
                            }
                        }

                        .col-lg-8 {
                            .post_locations {
                                margin: 0;

                                .border-bottom {
                                    //display: flex;
                                    //justify-content: space-between;
                                    //align-items: center;

                                    .reservation__option__content {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        width: 100%;

                                        span {
                                            margin: 0;
                                            font-size: 15px;
                                            line-height: 20px;
                                            font-family: "Open Sans", sans-serif;
                                            font-weight: 400;
                                            letter-spacing: normal;
                                            color: #4c4c4c;
                                        }

                                        .details {
                                            display: flex;
                                            align-items: center;

                                            .font-weight-bold {
                                                margin: 0;
                                                font-size: 15px;
                                                line-height: 20px;
                                                font-family: "Open Sans", sans-serif;
                                                font-weight: 400;
                                                letter-spacing: normal;
                                                color: #4c4c4c;
                                                margin-right: 40px;
                                            }

                                            .quantity {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;

                                                .text_quantity {
                                                    margin: 0;
                                                    font-size: 14px;
                                                    line-height: 24px;
                                                    font-family: "Open Sans",
                                                    sans-serif;
                                                    font-weight: 400;
                                                    letter-spacing: normal;
                                                    color: #000;
                                                    margin-right: 10px;
                                                }

                                                .button_quantity {
                                                    border: 1px solid #dddddd;
                                                    border-radius: 5px;
                                                    padding: 10px;
                                                    display: flex;
                                                    justify-content: space-around;
                                                    align-items: center;
                                                    height: 38px;
                                                    transition: background-color .3s ease;

                                                    &.disabled {
                                                        background-color: #eee;
                                                        cursor: not-allowed;

                                                        button {
                                                            cursor: not-allowed;
                                                        }
                                                    }

                                                    span {
                                                        margin: 0;
                                                        font-size: 14px;
                                                        line-height: 24px;
                                                        font-family: "Open Sans",
                                                        sans-serif;
                                                        font-weight: 400;
                                                        letter-spacing: normal;
                                                        color: #000;
                                                        padding: 1px 6px;
                                                    }

                                                    button {
                                                        border: none;
                                                        outline: none;
                                                        background-color: transparent;
                                                        color: #999999;

                                                        &:focus {
                                                            outline: none;
                                                            border: none;
                                                            background-color: transparent;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .gray-bg {
        .events-booking {
            .py-5 {
                .row {
                    .events-booking-map {
                        #select_reservation {
                            margin-bottom: 20px;
                            padding-top: 20px;

                            .col-lg-8 {
                                .post_locations {
                                    padding: 0 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#filter-event {
    .col-md-3 {
        margin-bottom: 22px;

        .card {
            height: 100%;
            margin-bottom: 0;

            .card-body {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .location{
                    flex-grow: inherit;
                }
            }
        }
    }
}

.row.ajax-load {
    .col-lg-3.col-md-4.col-sm-6 {
        .card.w-100 {
            .card-body {
                height: 102px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.payment_method__items {
    padding: 0 20px;

    .payment_method__item {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        label {
            position: relative;

            b {
                color: #000000;
                font-family: $montserrat;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 18px;
                margin-left: 20px;
            }

            input {
                display: none;

                &:checked ~ .payment_method__checkmark {
                    border: 6px solid #a6282c;
                }
            }

            .payment_method__checkmark {
                position: absolute;
                left: 0;
                height: 14px;
                width: 14px;
                border: 1px solid #dddddd;
                background-color: white;
                border-radius: 50%;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .item__content__wrapper {
            border-radius: 5px;
            background-color: #f4f4f4;
            padding: 10px;

            .item__content {
                padding: 0px;
                margin-bottom: 10px;

                p {
                    color: #000000;
                    font-family: $open-sans;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 17px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .enrollmentForm__terms div {
        display: flex;
        margin-bottom: 20px;

        input {
            margin-top: 3px;
            margin-right: 6.5px;
        }

        label {
            font-family: $open-sans;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 17px;
            color: #999999;
            margin-bottom: 0;

            a {
                color: #cf5b5e;
                font-family: $open-sans;
            }
        }
    }
}

.enrollmentForm__terms {
    padding: 0 20px;

    div {
        display: flex;
        margin-bottom: 5px;

        input {
            margin-top: 3px;
            margin-right: 6.5px;
        }

        label {
            font-family: $open-sans;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 17px;
            color: #999999;
            margin-bottom: 0;

            a {
                color: #cf5b5e;
                font-family: $open-sans;
            }
        }
    }
}

.events-booking-seats-proceed {
    border-top: 0;
    padding: 0 20px 30px 20px;

    .events-booking-seats-proceed-button {
        width: 100%;
        text-align: center;
        height: 50px;
        border-radius: 5px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: #a6282c;

        i {
            margin-left: 10px;
        }
    }

    .disabled {
        background-color: #ccc;
    }
}

.events-container {
    .sidebar-content {
        .sidebar-data {
            .pricing-list {
                li {
                    p {
                        color: #4C4C4C;
                        font-family: $open-sans;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 19px;
                        text-align: right;
                        font-weight: bold;

                        span {
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }
}

.events-booking {
    .events-booking-info {
        ul {
            padding: 0;
        }
    }

    .events-booking-gallery {
        max-width: 100% !important;

        img {
            width: 100%;
        }
    }

    a.go-back {
        color: #999999;
        font-family: $open-sans;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 17px;
    }

    .events-booking-map {
        padding-top: 8px;

        .map-navigation {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            border: 1px solid #dddddd;
            border-radius: 5px 5px 0 0;
            background-color: #f4f4f4;
            height: fit-content;
            padding: 5px 20px;

            .first-section {
                display: flex;
                justify-content: space-between;
            }

            p {
                margin-bottom: 0;
                color: #4c4c4c;
                font-family: $montserrat;
                font-size: 15px;
                font-weight: bold;
                letter-spacing: 1.07px;
                line-height: 24px;
            }

            ul {
                display: flex;
                margin: 0;
                padding: 0;

                li {
                    width: 31px;
                    height: 31px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    border: 1px solid #4c4c4c;

                    &:first-child {
                        margin-right: 8px;
                    }
                }
            }
        }

        #map {
            width: 100%;
            height: 400px;
            overflow: hidden;
            border: 1px solid #dddddd;
            border-radius: 0 0 5px 5px;
            background-color: white;
            border-top: 0px;


            .leaflet-pane {
                z-index: 1 !important;
            }

            @media (max-width: 700px) {
                .leaflet-interactive {
                    width: 50px !important;
                }
            }

            .seat-popup {
                display: flex;

                .popup-blob {
                    div {
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        margin-top: 2px;
                        margin-right: 5px;
                    }
                }

                .popup-info {
                    .seat-name {
                        margin: 0;
                        font-weight: bold;
                        text-transform: uppercase;
                    }

                    .seat-info {
                        margin: 0;
                        margin-bottom: 10px;
                        padding-bottom: 7px;
                        border-bottom: 2px solid #eee;
                    }

                    .seat-price {
                        display: block;
                    }
                }
            }

            .leaflet-popup-close-button {
                display: none;
            }

            .seat--selected {
                fill: #777777;
                stroke: #777777;
            }
        }
    }

    .events-booking-seats {
        margin-top: 8px;
        border: 1px solid #dddddd;
        border-radius: 5px;

        .events-booking-seats-head {
            height: fit-content;
            background-color: #f4f4f4;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 5px 20px;

            p {
                margin-bottom: 0;
                color: #4c4c4c;
                font-family: $montserrat;
                font-size: 15px;
                font-weight: bold;
                letter-spacing: 1.07px;
                line-height: 24px;
            }

            p:last-of-type {
                font-size: 10px;
            }
        }

        .events-booking-seats-items {
            padding: 0;
            margin: 0;
            background-color: white;
            max-height: 370px;
            overflow-y: auto;

            .cart-item {
                padding: 20px 13px;
                border-bottom: 1px solid #dddddd;

                @media (min-width:576px) {
                    padding: 27px 20px;
                }

                &:last-child {
                    border-bottom: none;
                }

                &.manual-cart-item {
                    .item-title {
                        text-transform: uppercase;
                    }

                    .manual-card-item-info {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-top: 20px;

                        p {
                            color: #4C4C4C;
                            font-family: $open-sans;
                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 24px;
                            margin-bottom: 0;
                        }

                        .manual-card-item-desc {
                            font-weight: normal;
                        }

                        .manual-card-item-price {
                            font-weight: bold;
                        }
                    }
                }

                .cart-item-head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .cart-main-info {
                        display: flex;

                        .item-circle {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }

                        .item-title {
                            color: #000000;
                            font-family: $montserrat;
                            font-size: 15px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 19px;
                            margin-bottom: 0;
                        }
                    }

                    .remove-item {
                        cursor: pointer;
                    }
                }

                .cart-item-detail {
                    margin-left: 30px;
                    color: #4c4c4c;
                    font-family: $open-sans;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                    margin-bottom: 1rem !important;
                }

                .card-item-price {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;
                    border-radius: 5px;
                    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
                    background-color: #f0f8ff;

                    @media (min-width:576px) {
                        padding: 13px 30px;
                    }

                    .dropdown-toggle {
                        padding: 0;

                        @media (min-width:576px) {
                            padding: 0.375rem 0.75rem;
                        }

                        .card-item-wrapper {
                            display: flex;
                            align-items: center;
                            max-width: 90%;

                            .card-item-details{
                                display: flex;
                                align-items: center;
                                max-width: 70%;

                                @media (min-width:576px) {
                                    max-width: 90%;
                                }

                                @media (min-width:992px) {
                                    max-width: 70%;
                                }

                                @media (min-width:992px) {
                                    max-width: 80%;
                                }


                                .card-item-type {
                                    color: #000000;
                                    font-family: $open-sans;
                                    font-size: 14px;
                                    letter-spacing: 0;
                                    line-height: 24px;
                                    margin-bottom: 0;
                                }

                                .card-item-description {
                                    color: #000000;
                                    font-family: $open-sans;
                                    font-size: 14px;
                                    letter-spacing: 0;
                                    line-height: 24px;
                                    margin-bottom: 0px;
                                    padding-left: 5px;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                }
                            }
                        }
                    }


                    .dropdown {
                        .btn {
                            &:focus {
                                border: 1px solid transparent;
                                outline: none;
                                box-shadow: none;
                            }
                        }

                        .dropdown-menu {
                            .dropdown-item {
                                cursor: pointer;
                                color: black;

                                &:hover {
                                    background-color: #eee !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .events-booking-seats-empty {
            padding: 50px 0 60px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #4c4c4c;
            font-family: $open-sans;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 19px;
            text-align: center;
        }

        .events-booking-seats-proceed {
            border-top: 0;
            padding: 30px 20px;

            .events-booking-seats-proceed-button {
                width: 100%;
                text-align: center;
                height: 50px;
                border-radius: 5px;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                background-color: #a6282c;

                i {
                    margin-left: 10px;
                }
            }

            .disabled {
                background-color: #ccc;
            }
        }

        .manual-reservation-buttons {
            border-top: 1px solid #dddddd;
        }
    }

    .events-content {
        p {
            color: #000000;
            font-family: $open-sans;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 24px;
        }

        p.events-content-title {
            color: #a6282c;
            font-family: $montserrat;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 19px;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.red-button {
    text-align: center;
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: #a6282c;
}

.red-button:hover {
    color: #fff;
}

.see-details {
    width: 250px;
    height: 30px;
    padding: 0 1rem;
    margin-bottom: 1rem;

    i {
        margin-left: 10px;
    }
}

.seat-prices {
    margin-bottom: 10px;
}

.proceed-to-booking-seats {
    display: none;
    height: 50px;
}

@media (max-width: 991px) {
    .proceed-to-booking-seats {
        display: flex !important;
        margin-bottom: 10px;
    }
}

.event-settings-link,
.register_to_proceed,
.leaflet-container .register_to_proceed {
    color: #cf5b5e;
}

.leaflet-container .register_to_proceed:hover {
    color: #000;
}

.reservation__option__price {
    white-space: nowrap;
}
