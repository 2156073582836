.pages-menu {
    background-color: #F4F4F4;
    border-radius: 0 0 5px 5px;
    margin-bottom: 40px;
    .parent {
        border-radius: 5px 5px 0 0;
        background-color: #832023;
        padding-top: 13px;
        padding-bottom: 13px;
        .parent-name {
            color: #FFFFFF;
            font-family: $montserrat;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 1.07px;
            line-height: 24px;
            text-align: center;
        }
    }
    .children {
        .child-col {
            padding-left: 30px;
            padding-right: 30px;
            .child-name {
                color: #000000;
                font-family: $montserrat;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 19px;
                padding: 17px 20px;
                border-bottom: 1px solid #DDDDDD;
                position: relative;
                i {
                    position: absolute;
                    left: 0;
                    bottom: 13px;
                    font-size: 13px;
                    letter-spacing: 0;
                    line-height: 22px;
                }
                &.active {
                    font-weight: bold;
                }
                @media(max-width: 767px) {
                    padding: 12px 15px;
                    i {
                        line-height: 18px;
                    }
                }
                @media(max-width: 576px) {
                    padding: 8px 5px;
                    i {
                        line-height: 7px;
                        left: -5px;
                    }
                }
            }
        }
    }
    > div:last-child {
        .child-col {
            .child-name {
                border-bottom: none;
            }
        }
    }
}

