.btn-outline-red {
    color: #CF5B5E;
    border: 1.5px solid #CF5B5E;
    font-weight: bold;
    font-size: 12px;
}

.btn-outline-red:hover {
    color: #FFF;
    background-color: #CF5B5E;
    border: 1.5px solid #cf5b5e;
}

.btn-outline-red:focus, .btn-outline-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(207, 91, 94, 0.25);
}

.ajax-pre-loader {
    border: 8px solid #fafafa;
    border-radius: 50%;
    border-top: 8px solid #A6282C;
    width: 120px;
    height: 120px;
    margin: 50px auto;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(720deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(720deg); }
}



@keyframes customLoaderOpenAnimation {
    0% { opacity: 0; }
    100% {  opacity: 1; }
}

@keyframes customLoaderCloseAnimation {
    0% { opacity: 1; }
    100% {  opacity: 0; }
}

.custom__loader {
    animation: customLoaderOpenAnimation .3s linear;

    &--close {
        animation: customLoaderCloseAnimation .3s linear;
    }
}
