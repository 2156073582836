.mobile-menu {
    height: 127px;
    @media (max-width:576px){
        height:96px;
    }
    #topbar {
        display: flex;
        justify-content: space-between;
        padding: 8px;
        position: fixed;
        background: white;
        z-index: 100;
        width: 100%;
    }
    .navbar-toggler {
        border: none;
    }
    .navbar {
        padding: 8px;
        position: fixed;
        background: white;
        z-index: 99;
        width: 100%;
        top: 30px;

        .navbar-dropdown-button {
            background-color: transparent;
            border: 0;
            font-size: 25px;
        }

        .dropdown-menu-items{
            width: 80vw;
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: white;
            padding: 40px 25px;
            transform: translateX(-100%);
            transition: transform .4s;

            &.show {
                transform: translateX(0);
            }

            .navbar-nav{
                li {
                    border-bottom: 1px solid #eee;
                    padding: 5px 0;

                    .show {
                        display: flex !important;
                        flex-direction: column !important;
                    }
                }
            }
        }

        img {
            height: 50px;
        }
        .global-search-trigger{
            position: absolute;
            right: 60px;
            top: 20px;
            font-size: 20px;
            color: #999999;
        }
        @media (max-width:576px) {
            top:28px
        }
    }
    .nav-item {
        .nav-link{
            color: black;
            font-family: $montserrat;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 15px;
        }
        .dropdown-menu {
            border: none;
            a{
                color: black;
                font-family: $montserrat;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 15px;
            }
            .dropdown-item {
                a{
                    color: black;
                    font-family: $montserrat;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 1px;
                    line-height: 15px;
                }
            }
        }
    }
    @media (min-width: 577px) {
        #topbar {
            padding: 16px 16px 0 ;
        }
        .navbar {
            padding: 16px;
            img {
                height: 65px;
            }
            .global-search-trigger{
                right: 75px;
                top: 33px;
            }
        }
    }
}
