#footer {
    font-size: 13px;
    .gray-text {
        color: #999999;
        a {
            color: white;
            font-weight: 400;
            &:hover {
                color: #999999;
                transition: all 0.15s ease-in;
            }
        }
        a.d-block {
            color: #999999;
            &:hover {
                color: white;
                transition: all 0.15s ease-in;
            }
        }
    }
    .back-to-top {
        svg {
            font-size: 24px;
            letter-spacing: 2px;
            line-height: 27px;
            text-align: right;
            path {
                fill: white;
            }
        }
        @media (max-width: 716px) {
            margin-top: -60px;
        }
    }
    .col-xs-6{
        @media (max-width:577px) {
            width: 50%;
        }
    }
    .copyright {
        @media (max-width: 716px) {
            width: calc(100% - 40px);
        }
    }
    .footer__email {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
