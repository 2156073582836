#site-header a {
    text-decoration: none;
    color: black;
}

#topbar{
    text-transform: uppercase;
    line-height: 13px;
    font-size: 10px;
    font-weight: 800;
    span {
        font-family: $montserrat;
        font-size: 10px;
        letter-spacing: 0.83px;
        line-height: 13px;
        margin: 0 4px;
    }
    .social {
        i{
            color: #A6282C;
            margin: 0 4px;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 12px;

            @media (max-width:991px) {
                font-size: 16px;
            }

            &:hover{
                color: black !important;
            }
        }
    }
    .shopping-cart  {
        i{
            margin: 0 5px;
            font-size: 13px;
            letter-spacing: 1.08px;
            line-height: 13px;

            @media (max-width:991px) {
                font-size: 16px;
            }
        }
    }
    .user {
        i {
            font-size: 13px;
            letter-spacing: 1.08px;
            line-height: 13px;

            @media (max-width:991px) {
                font-size: 16px;
            }
        }
    }
    .lang {
        i {
            font-size: 8px;
            letter-spacing: 0.67px;
            line-height: 8px;
        }
        .lang-dropdown {
            display: none;
            position: absolute;
            background: #fff;
            z-index: 100;
            ul {
                padding-left: 0;
                line-height: 29px;
                margin-bottom: 0;
                &:before{
                    content: "";
                    opacity: 1;
                    width: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    height: 5px;
                    background-color: #a6282c;
                    transition: opacity 0.4s ease, width 0.4s ease;
                    -webkit-backface-visibility: hidden;
                    -moz-backface-visibility: hidden;
                    -ms-backface-visibility: hidden;
                }
                li{
                    a{
                        &:hover{
                            color: #a6282c;
                        }
                    }
                }
            }
        }
        &:hover {
            .lang-dropdown {
                display: block;
            }
        }
    }
    a:hover{
        color: #A6282C;
        transition: all 0.15s ease-in;
    }
}

#site-header {
    background: #fff;
    position: relative;
    font-weight: 800;
    .header-inner {
        padding-top: 10px;
        max-width: 1260px;
        width: 100%;
        z-index: 100;
        margin: 0 auto;
        @media (min-width: 992px) {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
        .header-inner-wrapper {
            width: 100%;
            padding: 0 16px;
            .header-logo {
                img {
                    max-width: 150px;
                }
            }
            ul#menu-main-menu {
                margin-bottom: 0;
                min-width: 781px;
                text-align: right;
                padding: 0;
                display: flex;
                justify-content: space-between;
                > .active {
                    > a{
                        color: #a6282c;
                        height: 100%;
                        display: block;
                        position: relative;
                    }
                    span{
                        height: 100%;
                        &:after {
                            content: '';
                            opacity: 1;
                            width: 100%;
                            left: 0;
                            position: absolute;
                            bottom: 0px;
                            height: 5px;
                            background-color: #a6282c;
                            -webkit-transition: opacity .4s ease, width .4s ease;
                            -moz-transition: opacity .4s ease, width .4s ease;
                            -ms-transition: opacity .4s ease, width .4s ease;
                            -o-transition: opacity .4s ease, width .4s ease;
                            transition: opacity .4s ease, width .4s ease;
                            -webkit-backface-visibility: hidden;
                            -moz-backface-visibility: hidden;
                            -ms-backface-visibility: hidden;
                        }
                    }
                }
                .menu-item{
                    a{
                        font-family: $montserrat, sans-serif;
                        font-size: 12px;
                        letter-spacing: 1px;
                        font-weight: 800;
                        span{
                            line-height: 15px;

                        }
                    }
                    i{
                        font-size: 8px;
                        letter-spacing: 0.67px;
                        line-height: 8px;
                        &.fa-search {
                            font-size: 15px;
                            letter-spacing: 1.25px;
                            line-height: 15px;
                            color: #999999;
                        }
                    }
                }
                li.menu-item {
                    padding: 0 0 0 16px;
                }
                li{
                    &:hover {
                        color: #a6282c;
                    }
                }
                > li.menu-item {
                    display: inline-block;
                    > span {
                        color: red
                    }
                }
                > li.menu-item-has-children {
                    .sub-menu{
                        position: absolute;
                        z-index: 5;
                        min-width: 180px;
                        padding: 10px 0;
                        top: 100%;
                        -webkit-box-shadow: 0px 0px 5px rgba(0,0,0,0.20);
                        box-shadow: 0px 0px 5px rgba(0,0,0,0.20);

                        -webkit-animation-duration: .15s;
                        -moz-animation-duration: .15s;
                        -o-animation-duration: .15s;
                        animation-duration: .15s;

                        -webkit-animation-fill-mode: both;
                        -moz-animation-fill-mode: both;
                        -o-animation-fill-mode: both;
                        animation-fill-mode: both;

                        -webkit-animation-name: menu_animation;
                        -moz-animation-name: menu_animation;
                        -o-animation-name: menu_animation;
                        animation-name: menu_animation;
                        background-color: white;
                        display: none;

                        .menu-item{
                            a {
                                span {
                                    display: block;
                                    word-wrap: break-word;
                                    width: 100%;
                                    white-space: normal;
                                }
                            }
                        }
                    }
                    &:hover{
                        > ul.sub-menu {
                            display: block;
                            text-align: left;
                            width: 200px;
                            padding: 15px 0;
                            margin-top: -1px;
                            margin-left: -25px;
                            -webkit-box-shadow: 0px 30px 30px 0px rgba(0,0,0,0.25);
                            -moz-box-shadow: 0px 30px 30px 0px rgba(0,0,0,0.25);
                            //box-shadow: none;
                            &:before {
                                content: '';
                                opacity: 1;
                                width: 100%;
                                left: 0;
                                position: absolute;
                                top: 0;
                                height: 5px;
                                background-color: #a6282c;
                                -webkit-transition: opacity .4s ease, width .4s ease;
                                -moz-transition: opacity .4s ease, width .4s ease;
                                -ms-transition: opacity .4s ease, width .4s ease;
                                -o-transition: opacity .4s ease, width .4s ease;
                                transition: opacity .4s ease, width .4s ease;
                                -webkit-backface-visibility: hidden;
                                -moz-backface-visibility: hidden;
                                -ms-backface-visibility: hidden;
                            }
                            .sub-menu-item {
                                position: relative;
                                i{
                                    position: absolute;
                                    right: 10px;
                                    top: 15px;
                                }
                            }
                            li{
                                display: block;
                                padding: 10px;
                                span{
                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    > ul {
                        > li{
                            > ul{
                                display:block;
                            }
                            ul.third-level {
                                top: 0px;
                                left: 100%;
                                &:before {
                                    content: '';
                                    opacity: 1;
                                    width: 100%;
                                    left: 0;
                                    position: absolute;
                                    top: 0;
                                    height: 5px;
                                    background-color: #a6282c;
                                    -webkit-transition: opacity .4s ease, width .4s ease;
                                    -moz-transition: opacity .4s ease, width .4s ease;
                                    -ms-transition: opacity .4s ease, width .4s ease;
                                    -o-transition: opacity .4s ease, width .4s ease;
                                    transition: opacity .4s ease, width .4s ease;
                                    -webkit-backface-visibility: hidden;
                                    -moz-backface-visibility: hidden;
                                    -ms-backface-visibility: hidden;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#main-menu .menu-wrapper > ul > li > a {
    line-height: 62px;
}

@media (max-width: 769px){
    #topbar {
        display: none;
    }

    .header-desktop {
        display: none;
    }
    .navbar-toggler {
        position: relative;
        //right: 30px;
        &:focus {
            border: none;
            outline: none;
        }
    }
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: white!important;
}


@media (max-width: 991px) {
    #site-header {
        display: none;
    }
}
