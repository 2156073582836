body.login {
    overflow-y: scroll;
    overflow-x: hidden;
    .navbar {
        min-height: 96px;
        background-color: white;
        padding: 10px 60px !important;
        .btn-return-site {
            float: right;
            border: 2px solid #333333;
            padding: 11px 20px;
            margin-top: 22px;
            border-radius: 5px;
            color: #333333;
            text-transform: uppercase;
            font-size: 10px;
            line-height: 13px;
            font-weight: 600;
        }
        .btn-return-site:hover {
            color: #fff;
            background-color: #333333;
        }
    }
    .row {
        margin: auto;
        .login-sidebar {
            border-top: 0 !important;
            background: #f4f4f4 !important;
            margin-bottom: 0 !important;
            min-height: calc(100vh - 100px) !important;
            .inner-login-sidebar {
                min-height: 600px;
            }
            .login-container {
                top: 0 !important;
                padding: 0 !important;
                margin-top: 30px !important;
                p {
                    text-transform: capitalize !important;
                    color: #000000 !important;
                    font-size: 15px !important;


                    @media (min-width:768px) {
                        width: 90% !important;
                        margin-left: 5%;
                    }
                }
                form {
                    padding-top: 0 !important;
                }
                a {
                    font-size: 12px;
                    float: right;
                    color: #666666;
                    text-decoration: underline;
                }
                a:hover {
                    text-decoration: none;
                }
                .login-inner {
                    border:1px solid #e9e9e9;
                    border-radius: 5px;
                    background-color: white;
                    padding: 30px 20px;
                    .form-group {
                        border: none !important;
                        //border: 4px solid black !important;
                        width: 100%;
                        padding: 0 !important;
                        margin-bottom: 10px;
                        label {
                            font-size: 12px !important;
                            color: #000000;
                            font-weight: 600 !important;
                        }
                        .required-astrix {
                            color: #A6282C;
                        }
                        .form-control {
                            border: 1px solid #e9e9e9 !important;
                            height: 40px !important;
                            padding-left: 7px !important;
                        }
                        .invalid-feedback {
                            font-size: 75%;
                            color: #A6282C;
                            display:block
                        }
                    }
                    .remember-me-text {
                        padding:0 5px !important;
                        margin-bottom: 0 !important;
                        font-size: 13px;
                        font-weight: 400;
                        color: #CCCCCC;
                    }

                    .btn-block {
                        border-radius: 5px;
                        color: white;
                        background-color: #A6282C;
                        border: 1px solid #A6282C;
                        height: 40px;
                        transition: all 0.15s ease-in;
                        font-weight: 600;
                        letter-spacing: 1px;
                        margin: 15px 0;
                    }
                    .btn-block:hover {
                        color: #A6282C;
                        background-color: white;
                    }
                    @media (min-width:768px) {
                        width: 90% !important;
                        margin-left: 5%;
                    }
                }
                @media (max-width:575px) {
                    //padding-left: 60px !important;
                    //padding-right: 45px !important;
                    min-height: 500px !important;
                    height: 500px !important;
                }
            }
            @media (max-width:575px) {
                min-height: 500px !important;
                height: 700px !important;
            }
        }

    }

    footer {
        background-color:#222222 !important;
        padding:32px 50px;
        .copyright p {
            color: #666666;
            font-weight: 500;
            a {
                color: #dedede;
            }
        }
    }
}


#graph-wrapper {
    background-color: red !important;
    border: 2px solid black !important;
}

