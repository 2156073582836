#cookie-consent {
    .cc-revoke {
        display: none;
    }

    .cc-window {
        box-shadow: -1px -5px 9px #00000029;
        color: white;
        background-color: #a6282c;

        .cc-message {
            .cc-link{
                color: white;

                &:hover {
                    color: white;
                    text-decoration: underline;
                }
            }
        }

        .cc-compliance {
            .cc-btn {
                transition: background-color .3s ease;

                &:hover {
                    border-color: white;
                    background-color: white;
                }
            }
        }
    }
}
