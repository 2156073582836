.hero-owl {
    &.owl-carousel {
        position: absolute;
        z-index: 0;
        .item {
            img {
                max-height: 440px;
                min-height: 440px;
                object-fit: cover;
                object-position: center top;
            }
        }

        .owl-nav {
            position: absolute;
            top: 50%;
            left: 30px;
            right: 30px;
            height: 0;
            transform: translateY(-100%);

            .owl-prev {
                background-color: transparent !important;
                color: white !important;
                i {
                    font-size: 25px !important;
                    padding-right: 20px !important;
                }
            }

            .owl-next {
                background-color: transparent !important;
                color: white !important;
                i {
                    font-size: 25px !important;
                    padding-right: 0 !important;
                    padding-left: 20px !important;
                }
            }
        }
    }
}

.owl-carousel {
    @mixin nav-buttons {
        height: 60px;
        background-color: white !important;

        span {
            padding: 10px;
        }

        @media (max-width:992px) {

            span {
                padding: 20px;
                font-size: 40px !important;
            }
        }
    }

    .owl-prev {
        @include nav-buttons;
        @media (max-width: 767px) {
            display: none;
        }
    }
    .owl-next {
        @include nav-buttons;
        position: absolute;
        right: 0;

        i{
            font-size: 36px;
            letter-spacing: 0;
            line-height: 36px;
            color: white;
            @media (min-width: 1024px) {
                padding: 0 30px;
            }
        }

        @media (max-width: 767px) {
            display: none;
        }
    }


}
