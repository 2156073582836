.gray-bg {
    .news-content {
        .container-fluid {
            .main-text {
                & > h3 {
                    img {
                        max-width: 100%;
                    }
                }

                @media screen and (max-width: 992px) {
                    max-width: 100%;
                    flex: 1;
                    padding-right: unset;
                }
            }
        }
    }

    &.single-course-body {
        .container-fluid {
            .row {
                .col-lg-8 {
                    @media screen and (max-width: 767px) {
                        .row {
                            .col-12.body-title.pb-2.pl-0.pt-4 {
                                display: none;
                            }

                            &.galleria {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.teacher-data {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        flex-direction: column;

        .col-md-7.pt-3 {
            max-width: 100%;
        }

        .col-md-5.pt-3 {
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 500px) {
    .pages-hero-container,
    .single-course-hero-container,
    .events-hero-container {
        .container-fluid {
            .row {
                .page-title {
                    h1 {
                        font-size: 30px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .pages-hero-container,
    .single-course-hero-container,
    .events-hero-container {
        display: flex;
        align-items: center;
        min-height: 300px;
        // max-height: 300px;

        .container-fluid {
            height: 100%;

            .row {
                padding: 0;

                .breadcrumb {
                    margin-top: 0;
                }

                .page-title {
                    padding-bottom: 0;
                }
            }
        }
    }

    .events-hero-container {
        // align-items: unset;
        min-height: 250px;
        // max-height: 260px;

        .container-fluid {
            .row {
                .page-title {
                    h1 {
                        font-size: 30px;
                    }
                }
            }
        }
    }

    .events-hero-container.event-booking-page {
        display: flex;
        min-height: 250px;
        // max-height: 260px;

        .container-fluid {
            height: 100%;
            margin-top: 15px;

            .row {
                padding: 0;

                .breadcrumb {
                    margin-top: 0;
                }

                .page-title {
                    padding-bottom: 0;
                    margin-bottom: 0;

                    h1 {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .gray-bg {
        .events-container {
            & > .row {
                margin: 0 15px !important;
            }

            .row {
                .main-text {
                    .share-btn {
                        .col-md-3 {
                            padding: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .gray-bg {
        .events-container {
            & > .row {
                margin: 0 30px !important;
            }

            .row {
                .main-text {
                    .share-btn {
                        .col-md-3 {
                            &:first-of-type {
                                padding: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .gray-bg {
        .events-container {
            .row {
                .main-text {
                    .share-btn {
                        .col-md-3 {
                            button {
                                a {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .gray-bg {
        .events-container {
            .row {
                .sidebar {
                    .info {
                        .sidebar-content {
                            .row.sidebar-data {
                                margin-right: 0 !important;
                                margin-left: 0 !important;

                                .border-bottom.pt-3 {
                                    margin: 0 -15px;

                                    .col-md-6 {
                                        padding-left: 0 !important;
                                    }

                                    .col-md-4 {
                                        padding-left: 0 !important;
                                    }

                                    .col-md-6.text-right.text-xs-left.sidebar-dynamic-data {
                                        padding-right: 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .popup-form {
        padding: 15px !important;

        .modal-dialog {
            min-width: 250px;
        }
    }
}

@media screen and (max-width: 575px) {
    .single-course-hero-container {
        .container-fluid {
            .row {
                .page-title {
                    padding: 0;
                }
            }
        }
    }
}

.pages-hero-container {
    .container-fluid {
        .row {
            .page-actions {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                @media screen and (min-width: 768px) {
                    align-items: flex-end;
                }

                .pdf {
                    margin: 10px 0;
                    color: #fff;

                    h6 {
                        margin: 0;
                        padding-right: 7px;
                    }

                    &:hover {
                        color: #fff;
                    }

                    a {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-end;

                        &:hover {
                            color: #fff;
                        }
                    }

                    @media screen and (min-width: 768px) {
                        display: flex;

                        a {
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .gray-bg.single-course-body {
        .container-fluid {
            .row {
                .col-lg-12 {
                    .gray-bg.cms-page {
                        .container-fluid.news-content {
                            .row.mb-5 {
                                .col-md-6.col-lg-7 {
                                    .row {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
