.teachers {
    .teacher__institutes {
        .teacher__institutes__title {
            font-size: 14px;
            font-weight: bold;
        }

        .teacher__institutes__items {
            li {
                font-size: 12px;
            }
        }
    }
}
