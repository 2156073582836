// Error with bootstrap modal
//body, html {
//    overflow-x: hidden;
//}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
    font-family: "Montserrat", sans-serif;
}

ul,
li {
    list-style: none;
}

a {
    text-decoration: none;
    color: unset;
}

a:hover {
    text-decoration: none;
    color: #000;
}

button {
    &:hover {
        //border: none;
        //outline: none;
    }

    &:focus {
        border: none;
        outline: none;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.clearfix {
    display: block;

    &:after {
        content: "";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

.container-fluid {
    max-width: 1260px;
}

.position-right {
    float: right;
}

.position-left {
    float: left;
}

.inline-block {
    display: inline-block;
}

//.block {
//    display: block;
//}

@media (max-width: 576px) {
    .mt-sm-2 {
        margin-top: 0.5rem !important;
    }
    .text-xs-center {
        text-align: center !important;
    }
}

.gray-bg {
    background-color: #f4f4f4;
}

.card-body {
    padding: 22px 10px;

    .card-title {
        color: #000000;
        font-family: $montserrat;
        font-size: 20px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 24px;
        min-height: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
}

.hero-owl-title {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.search-bar-container {
    top: 235px;
}

.title {
    color: #000000;
    font-family: $montserrat;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 29px;
}

.subtitle {
    color: #000000;
    font-family: $montserrat;
    font-size: 22px;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 27px;
}

.desc {
    color: #000000;
    font-family: $open-sans;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
}

.red-text {
    color: #a6282c;
}

.white-text {
    color: #fff;
}

.link {
    font-family: $montserrat;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 18px;

    &:hover {
        color: inherit;
        cursor: pointer;
    }
}

.button {
    border: 2px solid #cf5b5e;
    border-radius: 5px;

    .button__link {
        color: #cf5b5e;
        font-size: 15px;
    }
}

.button:hover {
    background-color: #cf5b5e;
    transition: all 0.15s ease-in;

    .button__link {
        color: white;
    }
}

.projects-owl {
    padding-left: 15px;
    padding-right: 15px;

    .owl-item {
        img {
            max-width: 530px;
            border-radius: 5px;
            height: 320px;
            object-fit: cover;
        }

        .item {
            @media (max-width: 767px) {
               position: relative;
            }

            .info {
                position: absolute;
                top: 50%;
                //right: 50%;
                transform: translateY(-50%);
                width: 90%;
                margin: auto;
                left: 10px;
                @media (max-width: 1024px) {
                    //position: unset;
                    //top: unset;
                    //right: unset !important;
                    //transform: unset;
                    text-align: center;
                }
                @media (max-width: 767px) {
                    transform: none;
                    top: unset;
                    bottom: 0;
                    right: 20%;
                    text-align: left;
                    padding-left: 50px;
                    padding-bottom: 10px;
                    @media (max-width: 575px) {
                        padding-left: 20px;
                    }
                }

                span {
                    &.owl-title {
                        font-size: 30px;
                        font-weight: 600;
                        line-height: 37px;
                        letter-spacing: 1px;
                        text-shadow: 0px 0px 3px black;
                    }
                }

                .link {
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 1px;
                    line-height: 15px;
                    text-decoration: underline;
                    text-shadow: 0px 0px 3px black;
                }

                .owl-prev {
                    @media (max-width: 767px) {
                        display: block;
                    }
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }

            .label {
                text-transform: uppercase;
                font-weight: 800;
                max-height: 25px;
                position: absolute;
                right: 20px;
                top: 17px;
                border-radius: 3px;
                background-color: #ffffff;
                color: #a6282c;
                font-family: $montserrat;
                font-size: 12px;
                letter-spacing: 1px;
                line-height: 15px;
                text-align: center;
                padding: 5px 10px;
            }
        }
    }
}

.projects-owl .owl-item img {
    max-width: 530px;
    border-radius: 5px;
    @media (max-width: 768px) {
        margin: 0 auto;
        float: unset !important;
    }
}

//.projects-owl .owl-item:nth-child(odd) img {
//    float: right;
//}

//.projects-owl .owl-item:nth-child(even) .item .label {
//    right: unset;
//    left: 400px;
//}

.projects-owl .owl-item .item .label {
    @media (max-width: 768px) {
        // left: 60% !important;
        display: table;
    }
}

//.projects-owl .owl-item:nth-child(even) .item .info {
//    right: unset;
//    left: -70px;
//}

//.projects-owl .owl-item:nth-child(odd) .item .info {
//    right: 360px;
//}

.projects-container {
    .title {
        font-weight: 600;
    }

    @media (max-width: 1024px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .projects-owl {
        position: relative;
        width: 90vw;
        margin: auto;

        .owl-item {
            .item {
                min-height: 320px;
                //.item-image {
                //    min-height: 320px;
                //    width: 80%;
                //    margin-left: auto;
                //}

                img {
                    float: right;
                }
            }
        }

        .owl-nav {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
            height: 0px;

            .owl-prev {
                background-color: transparent !important;
                transform: translateX(-20px);
                color: white !important;

                i {
                    font-size: 25px !important;
                    padding-right: 20px !important;
                }
            }

            .owl-next {
                background-color: transparent !important;
                color: white !important;
                transform: translateX(20px);

                i {
                    font-size: 25px !important;
                    padding-right: 0 !important;
                    padding-left: 20px !important;
                }
            }
        }
    }
}

.hero-container {
    min-height: 440px;
    height: 100%;
    position: relative;
    background-color: transparent;
    @media (max-width: 575px) {
        min-height: 400px;
        height: 400px;
        .owl-stage-outer {
            height: 400px;
        }
    }

    .hero-title {
        font-family: $montserrat;
        font-size: 40px;
        letter-spacing: 0;
        line-height: 49px;
        margin-bottom: 20px;
        text-shadow: 0px 0px 3px black;
        @media (min-width: 992px) {
            margin-top: 90px;
        }
        @media (max-width: 576px) {
            margin-top: 60px;
        }
    }

    .hero-subtitle {
        font-family: $montserrat;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
        text-shadow: 0px 0px 3px black;
        @media (max-width: 575px) {
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
        }
    }

    .search-bar-row {
        @media (max-width: 1000px) {
            position: absolute;
            top: 30px;
            width: 100%;
        }
        @media (max-width: 991px) {
            top: 0;
            transform: translateY(50%);
        }
        @media (max-width: 576px) {
            top: 0;
            transform: translateY(0%);
        }
        @media (min-width: 1310px) {
            margin-left: -50px;
        }
    }

    .search-bar-col {
        @media (max-width: 1000px) and (min-width: 874px) {
            min-width: 320px;
        }
    }

    .search-bar {
        position: relative;

        &:focus {
            outline: none;
        }

        button {
            outline: none;
            border: none;
            position: absolute;
            top: 55%;
            right: 3%;
            background: transparent;

            &:focus {
                outline: none;
                border: none;
            }

            i {
                font-size: 18px;
                letter-spacing: 0;
                line-height: 20px;
                text-align: right;
            }

            @media (max-width: 575px) {
                top: 30%;
            }
        }

        input {
            height: 55px;
            border: none;
            border-radius: 5px;
            margin-top: 40px;
            padding-left: 23px;

            &::placeholder {
                color: #cccccc;
                font-family: $open-sans;
                font-size: 15px;
                font-weight: 800;
                //letter-spacing: 0;
                //line-height: 20px;
                @media (max-width: 991px) {
                    font-weight: 600;
                }
            }

            &:focus {
                outline: none;
            }

            @media (max-width: 575px) {
                margin-top: 0;
            }
        }
    }
}

.rib-container {
    background-color: transparent;

    .ribbon-container {
        position: relative;
        margin-top: -100px;

        .intro-ribbon {
            width: 100%;
            padding: 0 15px;

            .box-row {
                padding: 32px 24px;
                background-color: white;
                border-radius: 10px;
                box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
                -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);

                .single-feature {
                    @media (max-width: 767px) {
                        justify-content: center;
                        text-align: left;
                    }
                }

                @media (max-width: 767px) {
                    .px-sm-3 {
                        padding-bottom: 15px !important;
                        padding-top: 15px !important;
                    }
                }
            }

            svg {
                font-size: 44px;
                letter-spacing: 0;
                line-height: 49px;

                path {
                    fill: #000;
                }
            }

            .header {
                color: #000000;
                font-family: $montserrat;
                font-size: 20px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 15px;
            }

            .desc {
                color: #000000;
                font-family: $open-sans;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 18px;
            }

            .text-sm-center {
                @media (max-width: 570px) {
                    text-align: center;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }

            @media (max-width: 767px) and (min-width: 575px) {
                max-width: 510px;
                margin: auto;
            }

            .col-md-4.py-1 {
                .header {
                    color: #000000;
                    font-family: $montserrat;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 15px;
                    text-transform: uppercase;
                }

                .desc {
                    color: #a6282c;
                    font-family: $montserrat;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 18px;
                }
            }
        }
    }
}

.departments {
    background-color: #f4f4f4;

    .inner-departments {
        @media (max-width: 768px) {
            padding-top: 24px !important;
        }

        .departments-desc {
            .title,
            .desc {
                font-weight: bold;
            }
        }

        .department-owl {
            padding: 0 15px;
            @media (max-width: 420px) {
                .active {
                    max-width: 300px !important;
                }
            }
        }
    }

    .department-box {
        min-height: 278px;
        height: 100%;
        border-radius: 10px;
        background-size: 140% !important;
        background-position: center !important;
        transition: 0.15s background-size;
        display: flex;
        transition: background-size 0.3s;

        .inner-single-department {
            text-align: center;
            width: 50%;
            margin: auto;

            h4 {
                color: white;
            }

            .category-logo {
                margin: auto;
            }
        }

        &:hover {
            background-size: 110% !important;
        }
    }

    .button {
        padding: 6px 33.5px 8px;

        a {
            font-family: $montserrat;
            font-size: 12px;
            font-weight: 900;
            letter-spacing: 1px;
        }

        @media (max-width: 576px) {
            margin-top: 20px !important;
        }
    }

    .desc {
        @media (max-width: 570px) {
            display: inline-block;
        }
    }

    .address-section {
        max-width: 790px;
        margin-left: 0;
        margin-right: auto;

        .main-address,
        .secondary-address {
            h3 {
                color: #a6282c;
                font-family: $montserrat;
                font-size: 30px;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 37px;
                text-transform: capitalize;
            }

            .address-information {
                color: #000000;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 30px;
                margin-bottom: 20px;

                @media (max-width: 767px) {
                    padding: 1rem 0.5rem;
                }

                h5 {
                    font-size: 14px;
                    line-height: 30px;
                    display: inline;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }

        .secondary-address {
            margin-top: 30px;

            div:first-child {
                padding-right: 0;

                @media (min-width: 768px) {
                    padding-right: 15px;
                }
            }

            div:last-child {
                padding-left: 0;

                @media (min-width: 768px) {
                    padding-left: 15px;
                }
            }
        }
    }

    .transportation {
        display: table;
        padding: 30px 40px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
        @media (max-width: 767px) {
            padding: 35px 20px;
        }

        h4 {
            color: #000000;
            font-family: $montserrat;
            font-size: 17px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 19px;
        }

        .row {
            border-bottom: 2px solid #e3e3e3;
            padding: 10px 0;

            &:last-child {
                border-bottom: none;
            }

            .col-12 {
                h5 {
                    font-size: 15px;
                    line-height: 30px;
                    display: inline;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                p {
                    color: #4c4c4c;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                    margin-bottom: 10px !important;
                }

                .transportation-group {
                    padding-top: 10px;

                    :first-child {
                        color: #a6282c;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        padding-bottom: 20px !important;
    }
}

.award {
    padding-top: 44px;
    @media (max-width: 991px) {
        padding-top: 0;
    }

    img {
        width: 150px;
        max-height: 100px;
    }

    .info {
        .subtitle {
            font-weight: bold;
        }

        @media (max-width: 991px) and (min-width: 768px) {
            padding-left: 30px;
            padding-right: 0;
        }
        @media (max-width: 767px) {
            padding: 15px 0 20px 25px;
        }
        @media (max-width: 576px) {
            padding: 15px 25px 20px 25px;
        }
    }

    .text-xs-center {
        @media (max-width: 767px) {
            text-align: center !important;
        }
    }

    .btn-info {
        background: transparent;
        color: #cf5b5e;
        border: 2px solid #cf5b5e;
        width: 160px;
        height: 40px;
        font-size: 13px;
        line-height: 26px;
        font-weight: 600;

        &:hover {
            background: #cf5b5e;
            color: white;
            transition: all 0.15s ease-in;
        }
    }
}

.outer-award {
    padding-bottom: 64px;
    @media (max-width: 767px) {
        padding-bottom: 34px;
    }
    @media (max-width: 576px) {
        padding-bottom: 14px;
    }
}

.shop-btn {
    border-radius: 5px;
    background-color: #cf5b5e;
    padding: 3px 8px;
    font-family: $montserrat;
    font-size: 11px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    position: absolute;

    i {
        padding: 3px;
        font-size: 8px;
        letter-spacing: 0;
        line-height: 8px;
    }
}

.online-shop-container {
    .shop-desc {
        @media (max-width: 767px) {
            padding: 24px 0;
        }

        a.desc {
            float: right;
        }
    }

    .small-container {
        background: #fff;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 0 2px 5px 0 rgba(32, 0, 0, 0.2);

        img {
            border-radius: 5px;
            max-width: 108px;
        }

        font-family: $montserrat;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;

        .shop-btn {
            border: 1px solid #cf5b5e;
            bottom: 5px;
            right: 20px;
        }

        .shop-btn:hover {
            color: #cf5b5e !important;
            background: white;
            transition: all 0.15s ease-in;
        }
    }

    a {
        font-weight: 600;
    }

    a:hover {
        color: black;
    }

    span {
        font-weight: 700;
    }

    @media (max-width: 991px) {
        margin-top: 1em !important;
    }
    @media (max-width: 575px) {
        margin-top: 0.5em !important;
    }
}

.news.card {
    border: 0;
    border-radius: 10px;
    margin-bottom: 22px;

    .card-body {
        padding: 20px;
    }

    img {
        border-radius: 10px 10px 0 0;
        height: 160px;
        object-fit: cover;
    }

    .fallback-bg {
        height: 160px;
    }

    .suptitle,
    .time-stamp {
        i {
            font-size: 18px;
            letter-spacing: 0;
            color: #999999;
            padding-right: 10px;
        }

        span {
            color: #999999;
            font-family: $open-sans;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 17px;
            @media (max-width: 575px) {
                font-weight: 600;
            }
        }
    }

    .title {
        color: #000000;
        font-family: $montserrat;
        font-size: 18px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        height: 52px;
        @media (max-width: 575px) {
            font-weight: 600;
        }
    }
}

.news.card:hover {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.15s ease-in;
}

.news-container {
    background: #f4f4f4;

    .container-title {
        padding-top: 70px;

        .free-request {
            line-height: 30px;
        }

        div:first-child {
            padding-right: 0 !important;
            @media (max-width: 991px) {
                padding-left: 25px;
            }

            .title {
                font-size: 22px;
                @media (max-width: 575px) {
                    font-weight: 600;
                }
            }
        }

        .text-right {
            padding: 0 25px 0 5px;
            @media (max-width: 575px) {
                font-weight: 600;
            }
        }

        @media (max-width: 991px) {
            padding-top: 50px;
        }
        @media (max-width: 575px) {
            padding-top: 40px;
        }
    }

    .articles-owl {
        padding: 0 15px;
        @media (max-width: 420px) {
            .active {
                max-width: 300px !important;
            }
        }
    }
}



.newsletter {
    background: #a6282c;
    padding: 43px 0;

    .title {
        font-family: $montserrat;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 45px;
    }

    .form-col {
        text-align: center;
        @media (min-width: 991px) {
            text-align: right;
        }
    }

    .email {
        max-width: 500px;

        input[type="email"] {
            height: 45px;
            border-radius: 5px;
            background-color: #ffffff;
            border: none;
            padding: 12px 20px;
            width: 60%;
            min-width: 450px;

            &:focus {
                outline: none;
                border: none;
            }

            &::placeholder {
                color: #666666;
                font-family: $open-sans;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 20px;
            }

            @media (max-width: 677px) {
                width: calc(100% - 30px);
                margin: auto;
                min-width: 90%;
            }
        }
    }

    .button {
        padding: 11px 40px;
        margin-left: 10px;
        margin-top: -7px;
        color: white;
        border: 1px solid white;
        font-size: 14px;
        font-weight: 600;
        @media (max-width: 677px) {
            margin-top: 10px;
        }

        &:hover {
            color: #a6282c;
            background: white;
        }
    }

    @media (max-width: 991px) {
        text-align: center;
        padding: 30px 0;
    }
}

@media (max-width: 768px) {
    .w-sm-100 {
        width: 100% !important;
    }
}

@media (min-width: 992px) {
    .mobile-menu {
        display: none;
    }
}

.breadcrumb {
    ol {
        margin-bottom: 0;
        padding-left: 0;
    }

    li {
        display: inline-block;

        a {
            text-decoration: none;
            color: #cccccc;

            &:hover {
                color: unset;
            }
        }
    }
}

.pages-hero-container {
    min-height: 240px;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-color: #6b1214 !important;

    .row {
        padding: 100px 16px 82px 0;

        .breadcrumb {
            a {
                text-decoration: none;
                color: #cccccc;

                &:hover {
                    color: unset;
                }
            }

            background-color: transparent;
            color: #cccccc;
            font-family: $montserrat;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;
            @media (max-width: 767px) {
                margin-top: 60px;
            }
        }

        .page-title {
            color: #ffffff;
            font-family: $montserrat;
            font-size: 34px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 42px;
            @media (max-width: 767px) {
                padding-bottom: 60px;
            }
        }

        .hero-btn {
            color: #ffffff;
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 18px;
            font-weight: 600;
            padding: 10px 15px;
            background-color: #a6282c;
            max-width: 300px;
        }

        .hero-btn i {
            font-size: 15px;
            letter-spacing: 1.25px;
            line-height: 15px;
            margin-left: 10px;
        }

        .hero-btn:hover {
            color: #a6282c;
            background-color: white;
            transition: all 0.15s ease-in;
        }
    }
}

.course-with-best {
    background-color: #f4f4f4;
}

.body-text {
    padding-top: 50px;
    padding-bottom: 80px;

    .col-md-12 {
        @media (min-width: 768px) {
            column-count: 2;
            column-gap: 40px;
        }

        p {
            color: #000000;
            font-family: $open-sans;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 24px;
        }
    }
}

.course-categories {
    .category-img {
        max-width: 190px;
        border-radius: 10px;
    }

    .category-name {
        color: #a6282c;
        font-family: $montserrat;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 32px;
        position: relative;
        padding-bottom: 20.3px;
        text-overflow: ellipsis;
        white-space: normal;

        &:after {
            content: "";
            display: block;
            position: absolute;
            margin-top: 20px;
            height: 3px;
            width: 100%;
            background: linear-gradient(to right, #a6282c 20%, #cccccc 20% 100%);
        }

        &:hover {
            a {
                color: #a6282c;
            }
        }

        @media (max-width: 767px) {
            font-weight: 600;
        }
    }

    .category-exc {
        color: #000000;
        font-family: $open-sans;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: left;
        padding-top: 22.5px;
    }

    .category__view__more {
        color: #a6282c;
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: bold;
        border: 1px solid;
        padding: 2px 5px;
        border-radius: 5px;
        text-transform: capitalize;
        display: inline-block;

        &:hover {
            color: #fff;
            background-color: #a6282c;
        }
    }

    .course-box {
        min-height: 190px;
        min-width: 190px;
        max-height: 190px;
        max-width: 190px;
        border-radius: 10px;
        background-size: 100% !important;
        transition: background-size 0.3s;
        background-repeat: no-repeat;
        background-size: cover;

        .inner-single-course {
            height: 100%;
            display: flex;

            .course-logo {
                margin: auto;
                width: 70px;
            }
        }

        &:hover {
            background-size: 110% !important;
        }
    }
}

.courses {
    .card {
        border: none;

        .course-data {
            color: #000000;
            font-family: $montserrat;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;
            position: relative;
            top: -20px;
        }

        img {
            height: 200px;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .card-body {
        max-width: 80%;
        position: relative;
        top: -35px;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        padding: 27px 5px;

        .card-title {
            min-height: unset;
            margin-bottom: 0;
        }
    }
}

.news-hero-container {
    color: white;
    background-size: cover !important;

    .category {
        margin-top: 140px;
        font-family: $open-sans;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 17px;
        text-transform: uppercase;
        @media (max-width: 991px) {
            margin-top: 100px;
        }
        @media (max-width: 767px) {
            margin-top: 80px;
        }
        @media (max-width: 575px) {
            margin-top: 50px;
        }
    }

    .page-title {
        font-family: $montserrat;
        font-size: 34px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 42px;
        margin-top: 11px;
        margin-bottom: 11px;
    }

    .publish-time {
        margin-bottom: 200px;
        font-family: $open-sans;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 17px;
        @media (max-width: 991px) {
            margin-bottom: 150px;
        }
        @media (max-width: 767px) {
            margin-bottom: 130px;
        }
        @media (max-width: 575px) {
            margin-bottom: 110px;
        }
    }
}

.news-content {
    background-color: #ffffff;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    padding: 60px 0 80px 0;
    position: relative;
    border-radius: 5px;

    @media (min-width: 992px) {
        padding-left: 60px;
        padding-right: 40px;
    }

    .main-text {
        font-family: $open-sans;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 24px;

        @media (min-width: 992px) {
            padding-right: 60px;
        }

        ul,
        ul li,
        ol li {
            list-style: unset !important;
        }
    }

    .sidebar {
        max-width: 380px;

        .sidebar-title {
            color: #000000;
            font-family: $montserrat;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 19px;
        }

        .sidebar-content {
            .sidebar-item {
                margin-top: 20px;
                border-bottom: 1px solid #dddddd;
                padding-bottom: 20px;
                @media (max-width: 767px) {
                    text-align: center;
                }

                img {
                    width: 100%;
                    max-width: 100%;
                    border-radius: 5px;
                    height: fit-content;
                    max-height: 95px;
                    object-fit: cover;
                    @media (max-width: 767px) {
                        width: 80%;
                    }
                    @media (max-width: 767px) {
                        width: 90%;
                    }
                }

                .item-title {
                    color: #000000;
                    font-family: $montserrat;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 22px;
                    margin-bottom: 22px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .item-publish {
                    color: #cccccc;
                    font-family: $open-sans;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 17px;
                }
            }
        }

        .adv {
            margin-top: 70px;
            height: 180px;
            background: url("https://i.ibb.co/HY0XkMy/brani.png") center/cover;
            border-radius: 5px;
            padding: 5px;
            @media (max-width: 991px) {
                width: calc(90% - 30px);
            }

            .inner-adv {
                border: 1px solid white;
                border-radius: 5px;
                height: 100%;
                padding: 35px 50px;

                .adv-text {
                    color: #ffffff;
                    font-family: $montserrat;
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 24px;
                }

                .btn-shop {
                    padding: 10px 12px;
                    height: 40px;
                    width: 140px;
                    background: #cf5b5e;
                    font-size: 13px;
                    line-height: 15px;
                }

                .btn-shop:hover {
                    color: #cf5b5e !important;
                    background: white;
                }
            }
        }

        @media (max-width: 992px) {
            min-width: 100%;
        }
    }

    .share-btn {
        margin-top: 50px;
        margin-bottom: 50px;

        .btn {
            color: #fff;
            font-family: $montserrat;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 19px;
            min-width: 100%;
            text-align: left;
            padding: 10px 15px;

            i {
                font-size: 12px;
                margin-right: 10px;
            }
        }

        .facebook {
            background-color: #35569f;
            border: 1px solid #35569f;

            &:hover {
                background-color: white;

                a {
                    color: #35569f;
                }
            }
        }

        .twitter {
            background-color: #00acf6;
            border: 1px solid #00acf6;

            &:hover {
                background-color: white;

                a {
                    color: #00acf6;
                }
            }
        }

        .pinterst {
            background-color: #d9001b;
            border: 1px solid #d9001b;

            &:hover {
                background-color: white;

                a {
                    color: #d9001b;
                }
            }
        }

        .linkedin {
            background-color: #0077ba;
            border: 1px solid #0077ba;

            &:hover {
                background-color: white;

                a {
                    color: #0077ba;
                }
            }
        }
    }
}

.content-top {
    position: relative;
    top: -60px;

    .row {
        margin-right: 0;
        margin-left: 0;
    }
}

.grid-title {
    font-family: $montserrat;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 29px;
    display: block;
    padding-top: 60px;
    padding-bottom: 30px;
}

.single-course-hero-container {
    background-size: cover !important;

    .pt-100 {
        padding-top: 100px;
        padding-bottom: 100px;
        @media (max-width: 567px) {
            padding-top: 50px;
            padding-bottom: 90px;
        }
        @media (max-width: 991px) {
            padding-top: 75px;
            padding-bottom: 125px;
        }
    }

    .row {
        margin-left: 0;
        padding: 100px 0 82px 0;
    }

    .page-title {
        color: #ffffff;
        font-family: $montserrat;
        font-size: 34px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 42px;
    }

    .breadcrumb {
        background: transparent;
        color: #cccccc;
        font-family: $montserrat;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 14px;

        a {
            color: #cccccc;

            &:hover {
                color: unset;
            }
        }
    }

    .hero-btn {
        color: #ffffff;
        font-family: $montserrat;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 18px;
        font-weight: 600;
        padding: 10px 15px;
        background-color: #a6282c;
        max-width: 300px;

        i {
            font-size: 15px;
            letter-spacing: 1.25px;
            line-height: 15px;
            margin-left: 10px;
        }

        &:hover {
            color: #a6282c;
            background-color: white;
            transition: all 0.15s ease-in;
        }
    }

    .col-xs-12 {
        @media (max-width: 576px) {
            width: 100% !important;
            max-width: 100%;
            flex: 0 0 100%;
            text-align: left !important;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
}

.single-course-ribbon {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    top: -30px;

    @media (max-width:767px) {
        .border-right {
            border: none !important;
        }
    }

    @media screen and (min-width: 992px) {
        top: -60px;
    }

    .icon {
        font-size: 31px;
    }

    .header {
        color: #000000;
        font-family: $montserrat;
        font-size: 12px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 15px;
        text-transform: uppercase;
    }

    .desc {
        color: #000000;
        font-family: $montserrat;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
    }
}

.single-course-body {
    .course_body__content {
        @media (max-width:991px) {
            padding-right: 0px !important;
        }

        ul,
        ul li,
        ol li {
            list-style: unset !important;
        }

    }

    .cms-page {
        margin-top: -40px;
        margin-bottom: 88px;
        border-radius: 5px;

        .news-content {
            @media (max-width: 992px) {
                padding: 60px 15px 80px 15px;
            }

            #registration_summary {
                padding: 25px 0;
                border-radius: 0 0 5px 5px;

                #selected-subscription {
                    padding: 0 20px;

                    .selected-subscription__head {
                        display: flex;
                        align-items: baseline;
                        justify-content: space-between;
                        margin-bottom: 25px;

                        h3 {
                            text-transform: uppercase;
                            color: #000000;
                            font-family: $montserrat;
                            font-size: 15px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 19px;
                            margin-bottom: 0;
                        }

                        a {
                            &:hover {
                                color: black;
                            }

                            i {
                                font-size: 16px;
                                cursor: pointer;
                            }
                        }
                    }

                    .selected-subscription__body {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        p {
                            color: #4c4c4c;
                            font-family: $open-sans;
                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 24px;
                            margin-bottom: 0;

                            strong {
                                color: #000000;
                                font-family: $montserrat;
                                font-size: 15px;
                                font-weight: bold;
                                letter-spacing: 0;
                                line-height: 19px;
                            }
                        }
                    }
                }

                hr {
                    margin: 25px 0;
                }

                #grand-total {
                    padding: 0 20px;
                    display: flex;
                    justify-content: space-between;

                    p {
                        margin: 0;

                        strong {
                            text-transform: uppercase;
                            color: #000000;
                            font-family: $montserrat;
                            font-size: 15px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 19px;
                        }
                    }
                }
            }

            .payment_method__items {
                padding: 0 20px;

                .payment_method__item {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    label {
                        position: relative;

                        b {
                            color: #000000;
                            font-family: $montserrat;
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 18px;
                            margin-left: 20px;
                        }

                        input {
                            display: none;

                            &:checked ~ .payment_method__checkmark {
                                border: 6px solid #a6282c;
                            }
                        }

                        .payment_method__checkmark {
                            position: absolute;
                            left: 0;
                            height: 14px;
                            width: 14px;
                            border: 1px solid #dddddd;
                            background-color: white;
                            border-radius: 50%;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    .item__content__wrapper {
                        border-radius: 5px;
                        background-color: #f4f4f4;
                        padding: 10px;

                        .item__content {
                            padding: 0px;
                            margin-bottom: 10px;

                            p {
                                color: #000000;
                                font-family: $open-sans;
                                font-size: 12px;
                                letter-spacing: 0;
                                line-height: 17px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

            #enrollmentForm {
                .enrollment-form__wrapper {
                    border-radius: 0 0 5px 5px;

                    .dropdown {
                        button {
                            text-align: left;
                            position: relative;
                            color: #000000;
                            font-family: $open-sans;
                            font-size: 11px;
                            letter-spacing: 0;
                            line-height: 15px;
                            display: flex;

                            &:focus {
                                border: 1px solid #dddddd;
                                box-shadow: none;
                            }

                            &::after {
                                font-family: "Font Awesome 5 Free";
                                content: "\F078";
                                display: inline-block;
                                padding-right: 3px;
                                vertical-align: middle;
                                font-weight: 900;
                                border: none;
                                position: absolute;
                                right: 18px;
                            }

                            span {
                                max-width: 90%;
                                display: inline-block;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }

                            strong {
                                margin-right: 20px;
                                margin-left: 5px;
                            }
                        }

                        .dropdown-menu {
                            width: 100%;
                            border-radius: 0 0 5px 5px;
                            padding: 0 19px;
                            margin: 0;
                            z-index: 3;

                            &[x-placement="top-start"] {
                                border-radius: 5px 5px 0 0;
                                border-top: 1px solid #cccccc !important;
                                border-bottom: none;

                                & ~ button {
                                    border-radius: 0 0 5px 5px;
                                    border-bottom: 1px solid #cccccc;
                                    border-top: none;

                                    &::after {
                                        transform: rotate(180deg);
                                    }
                                }

                                li {
                                    &:first-child {
                                        border-top: none;
                                    }

                                    &:last-child {
                                        border-bottom: 1px solid black;
                                    }
                                }
                            }

                            li {
                                &:first-child {
                                    border-top: 1px solid black;
                                }

                                border-top: 1px solid #cccccc;
                                padding: 10px 0;
                                color: #000000;
                                font-family: $open-sans;
                                font-size: 11px;
                                letter-spacing: 0;
                                line-height: 24px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                span {
                                    display: inline-block;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                }

                                strong {
                                    margin-left: 5px;
                                }
                            }
                        }

                        &.show {
                            button {
                                border-radius: 5px 5px 0 0;
                                border-bottom: 0;
                                padding-bottom: 14px;
                            }

                            .dropdown-menu {
                                border-top: 0;
                            }
                        }
                    }

                    #is_band {
                        button {
                            line-height: 14px;
                        }
                    }
                }
            }

            .enrollmentForm__terms div {
                display: flex;
                margin-bottom: 20px;

                input {
                    margin-top: 3px;
                    margin-right: 6.5px;
                }

                label {
                    font-family: $open-sans;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 17px;
                    color: #999999;
                    margin-bottom: 0;

                    a {
                        color: #cf5b5e;
                        font-family: $open-sans;
                    }
                }
            }
        }
    }

    .enrollment-form#external_link {
        color: #cf5b5e;
    }

    .body-title {
        font-family: $montserrat;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 29px;
        margin: 20px 0;

        @media (min-width:768px) {
            margin-top: 0;
        }
    }

    img,
    iframe {
        max-width: 100%;
    }

    img {
        height: auto;
    }

    .galleria {
        padding-bottom: 80px;
        @media (max-width: 767px) {
            padding-bottom: 30px;
        }

        img {
            max-width: 100%;
            height: 190px;
            object-fit: cover;
        }
    }

    .featured-img {
        width: 100%;

        img {
            width: 100%;
        }

        @media (max-width: 768px) {
            text-align: center;
        }
    }

    .sidebar {
        border-radius: 5px;
        background-color: #ffffff;
        height: fit-content;
        padding: 40px;

        .sidebar-title {
            font-family: $montserrat;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 15px;
            padding-bottom: 20px;

            span {
                font-weight: 800;
            }
        }

        .course-location {
            color: #000000;
            font-family: $open-sans;
            letter-spacing: 0;
            padding-bottom: 20px;
            font-weight: 600;

            h6 {
                font-size: 14px;
            }
        }

        svg {
            font-size: 16px;
        }

        .teachers-list {
            img {
                max-width: 54px;
                border-radius: 50%;
            }

            .name {
                color: #a6282c;
                font-family: $open-sans;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                font-weight: 400;
            }
        }

        .download-docs {
            .name {
                color: #a6282c;
                font-family: $open-sans;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 28px;
                text-decoration: underline;

                &:hover {
                    color: black;
                }
            }

            i {
                color: #a6282c;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 28px;
                padding-left: 10px;
            }
        }

        .pop-up-btn {
            width: 100%;
            border: 2px solid #a6282c;
            border-radius: 5px;
            text-align: center;

            a {
                color: #a6282c;
                font-family: $montserrat;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 24px;
            }

            &:hover {
                background-color: #a6282c;

                a {
                    color: white;
                }
            }
        }
    }
}

.teachers {
    .teacher-data {
        img {
            border-radius: 50%;
            height: 300px;
            width: 300px;
            max-width: 300px;
        }

        .name {
            font-family: $montserrat;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 29px;
        }

        .deep-red-container {
            border-radius: 5px 0 0 5px;
            background-color: #832023;
            display: inline-block;
            padding: 9px 14px;

            svg {
                font-size: 22px;

                path {
                    fill: #fff;
                }
            }
        }

        .red-container {
            color: #ffffff;
            font-family: $montserrat;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 18px;
            display: inline-block;
            background-color: #a6282c;
            padding: 12.3px 25px 12px 12px;
            border-radius: 0 5px 5px 0;
            margin-left: -5px;
        }

        .deep-gray-container {
            border-radius: 5px 0 0 5px;
            background-color: #494b4f;
            display: inline-block;
            padding: 9px 14px;

            svg {
                font-size: 22px;

                path {
                    fill: #fff;
                }
            }
        }

        .gray-container {
            color: #ffffff;
            font-family: $montserrat;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 18px;
            display: inline-block;
            background-color: #797c80;
            padding: 12.3px 25px 12px 12px;
            border-radius: 0 5px 5px 0;
            margin-left: -5px;
        }
    }

    .teacher-body {
        color: #000000;
        font-family: $open-sans;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 24px;
    }

    .teaching-courses {
        .card {
            background: transparent;
        }

        img {
            height: 200px;
        }
    }
}

.popup-form {
    padding: 40px;
    border-radius: 10px;
    z-index: 2000;

    .modal-dialog {
        min-width: 600px;
        border-radius: 15px;

        .modal-body {
            padding: 40px;
        }
    }

    .main-title {
        font-family: $montserrat;
        font-weight: 800;
        font-size: 14px;
    }

    input[type="text"],
    input[type="email"] {
        width: 100%;
        border-radius: 5px;
        padding: 13px;
        margin-top: 5px;

        &:focus {
            outline: none !important;
        }
    }

    input[type="text"]:not(.is-invalid),
    input[type="email"]:not(.is-invalid) {
        border: 1px solid #e9e9e9;
    }

    label {
        font-family: $open-sans;
        padding-top: 5px;
        font-size: 13px;
        width: 100%;
    }

    .message-input {
        width: 100%;
        border-radius: 5px;
        padding: 13px;

        &:focus {
            outline: none !important;
        }
    }

    .message-input:not(.is-invalid) {
        border: 1px solid #e9e9e9;
    }

    .checkbox-text {
        font-size: 10px;
    }
}

.submit-btn {
    background: #a6282c;
    font-family: $montserrat;
    color: #fff;
    font-size: 15px;
    padding: 10px 40px;
    border-radius: 5px;
    border: none;

    i.arrow {
        padding-left: 10px;
        font-size: 12px;
    }
}

.events-grid {
    .card {
        img {
            height: 160px;
        }

        .title {
            margin-bottom: 39px;
        }

        .location {
            svg {
                float: left;
            }

            p {
                color: #333333;
                font-family: $open-sans;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 17px;
                margin-bottom: 0;
            }
        }
    }
}

.events-hero-container {
    max-height: 440px;
    padding-top: 20px;
    padding-bottom: 100px;
    height: 440px;
    display: flex;
    align-items: center;

    @media (max-width:767px) {
        padding-bottom: 20px;
        height: unset;
    }

    .row {
        padding-top: 162px;
        padding-bottom: 185px;

        .breadcrumb {
            background-color: transparent;
            color: #cccccc;
            font-family: $montserrat;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 15px;
            padding-left: 3px;

            a {
                color: #cccccc;

                &:hover {
                    color: unset;
                }
            }
        }

        .page-title {
            color: #ffffff;
            font-family: $montserrat;
            font-size: 34px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 42px;
            padding-left: 0;
            margin-bottom: 10px;
        }

        .page-category {
            border-radius: 3px;
            background-color: #ffffff;
            padding-left: 3px;

            p {
                text-transform: uppercase;
                color: #a6282c;
                font-family: $montserrat;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 15px;
                padding: 5px 9px;
            }
        }

        .cycle_label{
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 1px;
            line-height: 15px;
            padding: 5px 9px;
            background-color: #7c7c7c;
            border-radius: 3px;
            margin-left: 1rem !important;
        }
    }
}

.gray-bg {
    //padding-bottom: 80px;

    .news-content.events-container {
        margin-top: -60px;

        @media (max-width:767px) {
            margin-top: 0;
        }

        .card {
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
        }

        .card-body {
            svg {
                float: left;
            }

            .location {
                p {
                    color: #333333;
                    font-family: $open-sans;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 17px;
                    margin-bottom: 0;
                }
            }
        }

        .sidebar-data {
            svg {
                font-size: 16px;
                margin-right: 8px;
            }

            text-transform: uppercase;
            color: #000000;
            font-family: $montserrat;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 15px;

            .sidebar-dynamic-data {
                color: #4c4c4c;
                font-family: $open-sans;
                font-weight: 100;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
                text-align: right;
                @media (max-width: 570px) {
                    text-align: left !important;
                    padding-left: 10px;
                    margin-left: 32px;
                    margin-top: 10px;
                }
            }
        }

        .map {
            iframe {
                width: 100%;
            }
        }

        .sidebar-btn {
            a {
                border-radius: 5px;
                background-color: #cf5b5e;
                width: 100%;
                padding-top: 13px;
                padding-bottom: 13px;

                color: #ffffff;
                font-family: $montserrat;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 24px;
            }
        }
    }
}

.modal-popup-btn {
    &:focus {
        outline: none !important;
        box-shadow: none;
    }

    @media screen and (min-width: 489px) and (max-width: 576px) {
        margin-top: 0 !important;
    }

    @media screen and (min-width: 1152px) {
        margin-top: 0 !important;
    }
}

.complementary-activities {
    i {
        color: #a6282c;
    }
}

.cms-page {
    min-height: 52vh;

    img {
        max-width: 100%;
        height: auto;
    }
}

.fallback-bg {
    background: url("/storage/settings/August2020/UpVB43eeE5p554ODe7Vc.png");
    background-position: center;
    background-size: cover;
    border-radius: 0 0 10px 10px;
}

.courses {
    .card {
        background: transparent;
    }

    .fallback-bg {
        height: 200px;
    }

    .label {
        position: absolute;
        right: 5px;
        background: white;
        border-radius: 5px;
        padding: 3px 10px;
        margin-top: 5px;
        font-family: $montserrat;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 15px;
        text-align: center;
        color: #a6282c;
    }
}

.events-container {
    .fallback-bg {
        height: 160px;
    }
}

.dropdown-item:hover,
.dropdown-item:focus {
    background: transparent !important;
}

.text-xs-left {
    @media (max-width: 570px) {
        text-align: left !important;
    }
}

.course-price-text {
    font-family: $montserrat;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    text-align: right;
}

.course-price {
    font-family: $montserrat;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 42px;
    text-align: right;
    padding-left: 10px;
    @media (max-width: 576px) {
        line-height: 25px;
        font-size: 20px;
        font-weight: 600;
    }
}

.privacy-text-box {
    #main {
        border: 1px solid #ced4da;
        max-height: 200px;
        overflow-y: scroll;
        background: white;
        padding: 12px;
        font-size: 12px;
        font-family: $montserrat;

        h3 {
            font-size: 1.17em;
            font-weight: 700;
        }
    }
}

.fil-available-width {
    width: -webkit-fill-available !important;
}

#anchors {
    padding-left: 0;

    li {
        display: inline;
        margin: 0;
        padding: 0 10px;
        border-left: 1px #d2d2d2 solid;

        &:first-child {
            border-left: none;
            padding-left: 0;
        }
    }
}

.dots {
    li {
        list-style: none;
        margin-left: 25px;
    }

    @media (max-width: 767px) {
        padding-left: 25px;
    }
    @media (max-width: 576px) {
        padding-left: 10px;
    }
}

.special {
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #ffc;
    border-radius: 10px;
    @media (min-width: 992px) {
        max-width: 690px;
    }
}

ol.numList {
    @media (max-width: 767px) {
        padding-left: 25px;
    }
    @media (max-width: 576px) {
        padding-left: 0;
    }

    li {
        list-style: decimal;
        margin-left: 25px;
    }
}

.dloadBox {
    float: right;
    display: block;
    margin: 0 0 20px 20px;
    padding: 85px 20px 20px 20px;
    text-align: center;
    line-height: 1.2em;
    border-radius: 10px;
    @media (max-width: 576px) {
        margin: auto auto 10px auto;
    }

    &:hover {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    }
}

.dloadBox1 {
    display: block;
    //float: left;
    max-width: 330px;
    margin: 10px 0 20px 0;
    padding: 30px 20px 30px 20px;
    text-align: left;
    line-height: 2em;
    border-radius: 10px;
    background: #efefef;
}

.label.price.red {
    background-color: #a6282c;
    color: white;
}

.label.top-30 {
    top: 30px;
}

.sigle-page {
    .page-title {
        color: #ffffff;
        font-family: $montserrat;
        font-size: 34px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 42px;
    }
}

.main-text {
    h2 {
        color: #a6282c;
        font-family: $montserrat;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 37px;
        padding: 30px 0 10px;
    }

    h3 {
        color: #a6282c;
        font-family: $montserrat;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 32px;
        margin: 25px 0 10px;
    }

    h4 {
        color: #a6282c;
        font-family: $montserrat;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 29px;
    }

    p {
        color: #000000;
        font-family: $montserrat;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 24px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    iframe{
        max-width: 100%;
    }
}

//
//input[name='filename'] {
//    margin-left: 22px;
//    opacity: 0;
//    &:focus {
//        border: none;
//        outline: none;
//    }
//    &:hover {
//        border: none;
//        outline: none;
//    }
//}

.upload-file-btn {
    position: relative;
    top: 16px;
    padding: 2px 50px;
    color: #a6282c;
    border-color: #a6282c;

    &:hover {
        background-color: #a6282c;
        border-color: #a6282c;
    }
}

.lecturer-grid-name {
    color: #000000;
    font-family: $montserrat;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.83px;
    line-height: 24px;

    a {
        &:hover {
            color: #a6282c;
        }
    }
}

.lecturer-information {
    padding-left: 1.5rem !important;
    padding-top: 1.5rem;

    @media (min-width: 768px) {
        padding-left: 0 !important;
        padding-top: 0;
    }
}

.lecturer-grid-desc {
    color: #000000;
    font-family: $open-sans;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
    max-height: 96px;
}

.more-lecturer {
    .btn-more {
        border: 2px solid #cf5b5e;
        color: #cf5b5e;
        background-color: transparent;
        padding: 10px 30px;
        line-height: 15px;

        &:hover {
            color: white;
            background-color: #cf5b5e;
            transition: all 0.15s ease-in;
        }
    }
}

.courses-list {
    color: #a6282c;
    font-family: $open-sans;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 28px;
    text-decoration: underline;

    a {
        &:hover {
            color: #a6282c;
        }
    }
}

.lecturer-grid-img {
    border-radius: 50%;
    width: 257px !important;
    margin: auto;

    @media (min-width: 768px) {
        width: 100% !important;
    }
}

.float-right.red-text.link {
    margin-top: 10px;
}

.events-container {
    .location > img {
        width: 13px;
        margin-right: 7px;
    }

    .card-course {
        .card-title {
            font-size: 18px;
            letter-spacing: 0;
            line-height: 22px;
        }

        .card-text.location {
            font-family: $open-sans;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 17px;
        }
    }

    .horizontal-card {
        position: relative;

        .card-img-top {
            width: 100%;
            height: fit-content;
            max-height: 140px;
            object-fit: cover;
            border-top-right-radius: 0;
            border-bottom-left-radius: 10px;
        }

        .card-body {
            padding: 15px 0;
        }

        .card-title {
            color: #000000;
            font-family: $montserrat;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
            min-height: unset;
            padding-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .card-text {
            color: #000000;
            font-family: $open-sans;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 18px;
            padding-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .location {
            color: #333333;
            font-family: $open-sans;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 17px;
            position: absolute;
            bottom: 17px;
        }
    }

    .grid-template-event-details {
        display: grid;
        grid-template-areas:
                "main-text main-text sidebar"
                "related related sidebar";

        .main-text {
            grid-area: main-text;
        }

        .sidebar {
            grid-area: sidebar;
        }

        .related {
            grid-area: related;
        }
    }
}

.complementary-activities {
    background-color: white;
}

.event-filter {
    //padding-top: 60px;
    @media (max-width:767px) {
        margin-bottom: 10px;
        background-color: red;
    }

    .filter {
        background-color: white;
        height: 40px;
        padding: 8px 15px;

        span {
            color: #000000;
            font-family: $open-sans;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 24px;
        }
    }
}

.filter-area {
    background-color: white;
    padding: 16px 10px;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

    .filter-area-title {
        font-family: $montserrat;
        font-size: 15px;
        font-weight: 800;
        letter-spacing: 1px;
        padding: 10px 0;
    }

    .filter-options {
        font-family: $montserrat;
        font-size: 13px;
        letter-spacing: 1px;

        .form-group {
            span {
                margin-left: 8px;
            }
        }
    }
}

.filter-container {
    z-index: 1;
    display: none;

    @media (max-width: 1320px) {
        left: 0 !important;
    }
}

.open-filter {
    display: inline-block;
}

.close-filter {
    display: none;
}

.filter-btn {
    &:hover {
        cursor: pointer;
    }
}

.book-data {
    svg {
        font-size: 16px;
        margin-right: 9px;
    }

    p {
        margin: 0;
    }
}

.book-data-title {
    display: inline-block;
    color: #000000;
    font-family: $montserrat;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 800;
    text-transform: uppercase;
}

.enrollment-form {
    border: 1px solid #dddddd;
    border-radius: 0 0 5px 5px;
    padding: 25.5px 20.5px;
}

.fossano-form {
    border: 1px solid #dddddd;
    border-radius: 0 0 5px 5px;
    padding: 25.5px 20.5px;
}

.form-header {
    padding: 14.5px 20.5px;
    border: 1px solid #dddddd;
    border-radius: 5px 5px 0 0;
    background-color: #f4f4f4;
    border-bottom: 0;
    color: #4c4c4c;
    font-family: $montserrat;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}

.turn-back {
    color: #999999;
    font-size: 12px;
    letter-spacing: 0;

    span {
        font-family: $open-sans;
        line-height: 17px;
    }

    i {
        line-height: 12px;
        margin-right: 5px;
    }
}

.enrollment-form {
    padding: 13px 19px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    background-color: #ffffff;
    color: #000000;
    font-family: $open-sans;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 13px;
    width: 100%;
}

.fossano-form {
    padding: 13px 19px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    background-color: #ffffff;
    color: #000000;
    font-family: $open-sans;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 13px;
    width: 100%;
}

.redd-asterix {
    color: #cf5b5e;
    font-family: $montserrat;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}

//.enrollment-options {
//    color: #000000;
//    font-family: $open-sans;
//    font-size: 13px;
//    font-weight: bold;
//    letter-spacing: 0;
//    line-height: 24px;
//}

.form-attachment {
    label[for="bio"] {
        font-family: $open-sans;
        border-radius: 5px;
        background-color: #4c4c4c;
        margin-bottom: 0;
        color: white;
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;

        @media (max-width: 576px) {
            padding: 19px 25px;
            margin-top: 10px;
        }
    }

    #bio {
        display: none;
    }
}

.file-name {
    height: 100%;
    line-height: 23px;
}

.fossano-button {
    height: 45px;
    border: 2px solid #a6282c;
    background-color: #a6282c;
    color: white;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 24px;
    font-weight: 600;
    padding: 9px 36px 0 36px;
}

.btn-grey {
    border: 2px solid #cccccc;
    background-color: #cccccc;
}

.fossano-button:hover {
    color: #a6282c;
    background-color: transparent;
    transition: all 0.15s ease-in;
}

.btn-grey:hover {
    border: 2px solid #cccccc;
    color: #cccccc;
}

.contact-form {
    max-width: 790px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid #cccccc;

    .form-header {
        min-height: 50px;
        max-height: 50px;
        background-color: #a6282c;
        text-align: center;
        display: block;

        h5 {
            color: white;
            line-height: 29px;
            font-size: 24px;
            margin-top: -5px;
            font-weight: bold;
            letter-spacing: 1px;
        }
    }

    .form-body {
        background-color: white;
        padding: 20px 30px;
        border-radius: 0 0 5px 5px;

        .form-group {
            padding: 5px 25px;
            margin-bottom: 0;

            label {
                font-size: 13px;
                line-height: 19px;
                font-weight: bold;
                color: #000000;
                margin-bottom: 0;

                span {
                    color: #cf5b5e;
                }
            }

            input[type="text"],
            textarea {
                padding-left: 10px;
                height: 50px;
                border-radius: 5px;
                border: 1px solid #dddddd;
                width: 100%;
            }

            input[type="checkbox"]:checked {
                background-color: black;
            }

            input:hover,
            textarea:hover {
                border: 1px solid #a6282c;
                transition: all 0.15s ease-in;
            }

            input:focus::placeholder {
                color: transparent;
            }

            input[readonly] {
                background-color: #e9ecef;
                opacity: 1;
            }

            textarea {
                min-height: 100px;
                max-height: 200px;
                padding-top: 10px;
            }

            p {
                font-size: 12px;
                line-height: 17px;
                color: #999999;
                display: inline;

                a {
                    color: #cf5b5e;
                }

                a:hover {
                    color: #a6282c;
                    text-decoration: none;
                }
            }

            .btn-submit {
                background-color: #a6282c;
                color: white;
                width: 160px;
                margin: 10px auto 20px auto;
                letter-spacing: 1px;
                left: 50%;
                display: block;
                border-radius: 5px;
                border: 1px solid #a6282c;
            }

            .btn-submit:hover {
                background-color: white;
                color: #a6282c;
                transition: all 0.15s ease-in;
            }

            .invalid-feedback {
                display: block !important;
                margin-top: 0 !important;
                font-size: 70% !important;
            }
        }
    }

    @media (max-width: 767px) {
        .form-header {
            min-height: 35px;
            max-height: 35px;

            h5 {
                line-height: 23px;
                font-size: 18px;
                margin-top: -8px;
            }
        }
        .form-body {
            padding: 20px 40px;

            .form-group {
                input[type="text"],
                textarea {
                    padding-left: 10px;
                    height: 35px;
                    border-radius: 3px;
                }

                textarea {
                    min-height: 70px;
                    max-height: 100px;
                    padding-top: 5px;
                }
            }
        }
    }
}

.location-map {
    min-height: 250px;
    height: 250px;
    min-width: 100%;
    border-radius: 4px;
}

//Reserve a hall styling
.hall-content {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    padding: 60px 30px 80px 30px;
    position: relative;
    top: -60px;

    @media (min-width: 992px) {
        padding-left: 60px;
        padding-right: 40px;
    }

    h2 {
        font-size: 30px;
        line-height: 37px;
        color: #a6282c;
        letter-spacing: 1px;
    }

    p {
        font-size: 15px;
        line-height: 24px;
        color: #000000;
    }

    .btn-download {
        border: 2px solid #a6282c;
        border-radius: 5px;
        color: #a6282c;
        width: 100%;
        height: 50px;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 24px;
        font-weight: 700;
        padding-top: 12px;
        margin: 30px 0;
    }

    .btn-download:hover {
        color: white;
        background-color: #a6282c;
        transition: all 0.15s ease-in;
    }

    .prices {
        border-bottom: 1px solid #dddddd;
        padding-bottom: 23px;

        .single-slot {
            padding-top: 10px;

            h6 {
                font-size: 14px;
                color: #4c4c4c;
                font-weight: 700;
            }

            .hours {
                padding-left: 20px;

                h6 {
                    font-weight: 500;
                }
            }

            .price {
                text-align: right;
            }

            @media (max-width: 992px) {
                .price {
                    text-align: left;
                }

                .hours {
                    padding-left: 0px;
                }
            }
        }
    }

    .galleria {
        margin-right: 0;
        margin-left: 0;

        .galleria-title {
            display: none;
        }

        img {
            object-fit: cover;
            border-radius: 5px;
            height: 60px;
            width: 100%;
        }

        .owl-preview img {
            width: 100%;
            height: 250px;
            margin-bottom: 20px;
        }

        .owl-carousel {
            position: relative;

            .owl-stage-outer {
                //width: 95%;
                padding-left: 5%;
                padding-right: 5%;
                margin: auto;

                .owl-stage {
                    display: flex;

                    .owl-item {
                        margin: 0 5px;
                    }
                }
            }

            .owl-nav {
                height: 0px;
                width: 100%;
                position: absolute;
                top: 0;

                span {
                    font-size: 20px;
                }
            }

            .owl-dots {
                display: none;
            }
        }
    }

    //Reserve form styling
    #reserveForm {
        .enrollment-form {
            border-radius: 0 0 5px 5px;

            input[type="date"] {
                height: 50px;
            }

            .form-group-btn {
                margin-top: auto;

                .btn-delete {
                    color: white;
                    background-color: #cf5b5e;
                    font-size: 10px;
                    padding: 5px 15px;
                    border-radius: 20px;
                    border: 1px solid #cf5b5e;
                    margin-bottom: calc(10px + 1rem);
                }

                .btn-delete:hover {
                    background-color: #a6282c;
                    border: 1px solid #a6282c;
                }
            }
        }

        input.enrollment-form,
        select.enrollment-form {
            border-radius: 5px;
        }

        .reservation-wrapper {
            .reservation-inputs {
                .dropdown {
                    .dropdown-toggle {
                        box-sizing: border-box;
                        height: 50px;
                        width: 100%;
                        border: 1px solid #dddddd;
                        border-radius: 5px;
                        background-color: #ffffff;
                        color: black;
                        position: relative;
                        text-align: left;

                        &::after {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            margin-right: 16px;
                        }
                    }

                    .dropdown-menu {
                        width: 100%;
                        max-height: 300px;
                        overflow-y: scroll;

                        .dropdown-item {
                            padding: 10px 15px;
                            cursor: pointer;
                            font-weight: bold;

                            &:hover {
                                background-color: #eee !important;
                            }
                        }

                        .disabled {
                            font-weight: unset;
                        }
                    }
                }

                #price {
                    box-sizing: border-box;
                    height: 50px;
                    border: 1px solid #dddddd;
                    border-radius: 5px;
                    background-color: #ffffff;

                    display: flex;
                    align-items: center;

                    p {
                        padding-left: 10px;
                        font-size: 14px;
                        margin: 0px;
                        font-weight: 800;
                    }
                }
            }

            .loader {
                &-hidden {
                    display: none;
                }

                .ajax-pre-loader {
                    height: 50px;
                    width: 50px;
                    margin: auto;
                    border: 6px solid #fafafa;
                    border-top: 6px solid #a6282c;
                }
            }
        }
    }

    input.fossano-form {
        border-radius: 5px;
    }

    //Summary form styling
    .summary-form {
        width: 100%;
        margin: 30px 0;

        .form-header h5 {
            text-transform: uppercase;
            color: #4c4c4c;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 0;
            display: inline-block;
            margin-left: calc(50% - 95px);
            font-weight: 600;
        }

        .summary-inner {
            padding: 30px 20px;
            border: 1px solid #dddddd;
            border-radius: 0 0 5px 5px;

            h6 {
                color: #cccccc;
                font-size: 14px;
                line-height: 19px;
            }

            .summary-reservations {
                border-bottom: 1px solid #dddddd;

                .summary-header {
                    display: flex;
                    justify-content: space-between;

                    .summary-date {
                        font-size: 15px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                }

                .summary-items {
                    margin-top: 15px;
                    padding: 0;

                    li {
                        display: flex;
                        justify-content: space-between;

                        p {
                            font-size: 15px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 19px;
                        }
                    }
                }
            }

            .summary-total {
                .summary-total-price {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 25px;
                    font-size: 15px;
                    font-weight: bold;
                }
            }
        }

        .payment-methods {
            padding: 30px 20px;

            .single-payment {
                padding-top: 10px;

                label {
                    font-size: 14px;
                    line-height: 18px;
                    color: #000000;
                    font-weight: 600;
                }

                .single-payment-body {
                    background-color: #f4f4f4;
                    border: 1px solid #dddddd;
                    border-radius: 5px;
                    padding: 10px;

                    p {
                        font-size: 12px;
                        line-height: 14px;
                        color: black;
                    }
                }
            }
        }

        .btn-reserve {
            border: 2px solid #cccccc;
            background-color: #cccccc;
            border-radius: 5px;
            color: white;
            width: 100%;
            height: 50px;
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 24px;
            font-weight: 700;
            padding-top: 12px;
        }

        .btn-reserve:hover {
            color: #cccccc;
            background-color: transparent;
            transition: all 0.15s ease-in;
        }

        .btn-red {
            background-color: #a6282c !important;
        }

        p {
            color: #999999;
            display: inline;

            a {
                text-decoration: none;
                color: #cf5b5e;
            }

            a:hover {
                color: #a6282c;
            }
        }
    }
}

@media (max-width: 991px) {
    .hall-content {
        .galleria {
            margin-top: 5vh;

            .galleria-title {
                display: inline-block;
                color: #a6282c;
                margin-left: calc(50% - 30px);
            }

            img {
                object-fit: cover;
                border-radius: 5px;
                height: 40vh;
                max-height: 400px;
                width: 100%;
            }

            .owl-preview {
                display: none;
            }
        }
    }
}

.accordion .card {
    border: 0;

    .card-header {
        border-bottom: 0;

        h2 .btn-link {
            color: #a6282c;
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            text-align: left;
        }
    }
}

span.error {
    font-size: 12px;
    color: #cf5b5e;
    font-family: "Open Sans", sans-serif;
}

.errors_alert {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    background-color: rgb(0, 0, 0, 0.5);

    .error_alert {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 500px;
        height: 100px;
        margin: 10px;
        padding: 15px 60px;
        color: rgba(255, 255, 255);
        background: rgb(166, 40, 44);
        border-radius: 5px;

        .error_remove {
            position: absolute;
            top: 2px;
            right: 4px;
            .remove_alert_error {
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 767px) {
    .errors_alert {
        .error_alert {
            width: 80%;
        }
    }
}
