@media screen and (max-width: 575px) {
    .rib-container {
        .ribbon-container {
            .intro-ribbon {
                .row.box-row.px-5 {
                    .col-md-4.py-1 {
                        padding: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 399px) {
    .rib-container {
        .ribbon-container {
            .intro-ribbon {
                .row.box-row.px-5 {
                    padding: 0 !important;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .rib-container {
        .ribbon-container {
            .intro-ribbon {
                .row.box-row.px-5 {
                    display: flex;
                    flex-direction: column;

                    .col-md-4.py-1 {
                        padding: 0;
                        max-width: unset;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1300px) {
    .rib-container {
        .ribbon-container {
            .intro-ribbon {
                .row.box-row.px-5 {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    column-gap: 15px;
                    padding: 15px 0 !important;

                    .col-md-4.py-1 {
                        max-width: unset;
                        padding: 0;

                        .row {
                            flex-wrap: unset;
                            flex-direction: column;

                            .col-sm-2.pr-2.text-center,
                            .col-sm-9.text-xs-center {
                                max-width: unset;
                                flex: unset;
                            }

                            .col-sm-9.text-xs-center {
                                margin-top: 20px;

                                .header {
                                    text-align: center;
                                    display: block;
                                }

                                br {
                                    display: none;
                                }

                                .desc {
                                    display: block;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .gray-bg.cms-page {
        .news-content {
            padding-right: 60px;
        }
    }
}

@media screen and (min-width: 768px) {
    .departments.py-5 {
        .container-fluid.pt-5 {
            .row.no-gutters.pt-2.pb-2 {
                .col-md-8.col-sm-12.address-section {
                    padding-right: 20px !important;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .col-lg-12.col-md-8.col-sm-12.pb-5.mx-auto {
        max-width: unset;
        flex: unset;
    }
}

@media screen and (max-width: 767px) {
    .gray-bg.cms-page {
        .news-content {
            .cms-page {
                .container-fluid {
                    .row {
                        .mx-auto {
                            h2,
                            p {
                                padding: 0 20px !important;

                                img {
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
