$owl-image-path: "~owl.carousel/src/img/";

@import 'node_modules/leaflet/dist/leaflet';

@import 'fonts',
'node_modules/@fortawesome/fontawesome-free/scss/fontawesome',
'node_modules/@fortawesome/fontawesome-free/scss/solid',
'node_modules/@fortawesome/fontawesome-free/scss/regular',
'node_modules/@fortawesome/fontawesome-free/scss/brands',
'node_modules/owl.carousel/src/scss/owl.carousel',
'node_modules/bootstrap/scss/bootstrap',
'node_modules/toastr/toastr',
'node_modules/dropzone/dist/dropzone',
'~cookieconsent/build/cookieconsent.min.css',
'header',
'footer',
'mobileMenu',
'slider',
'ajaxLoader',
'searchOverlay',
'pagesMenu',
'basicLayout',
'events',
'style',
'corsi',
'servizi',
'informazioni',
'cookie-consent',
'lecturers/index';
